export const onboardingTranslation = {
  buttonPass: {
    fr: "Passer",
    en: "Skip",
  },
};

export const translation = {
  orderSection: {
    title: {
      fr: "Réorganiser les champs",
      en: "Rearrange fields",
    },
    subtitle: {
      fr: "Déplacez les blocs dans l'ordre que vous souhaitez afficher vos informations",
      en: "Move the blocks in the order you want to display your information",
    },
    button: {
      fr: "Sauvegarder",
      en: "Save",
    },
  },
  ContactLock: {
    title: {
      fr: "Code d'identification",
      en: "Identification code",
    },
    subtitle: {
      fr: "Saisir le mot de passe temporaire",
      en: "Enter the temporary password",
    },
    error: {
      fr: "Le mot de passe est incorrect !",
      en: "Password incorrect!",
    },
    button: {
      fr: "Valider",
      en: "Submit",
    },
  },
  loginPage: {
    greeting: {
      fr: "Bonjour",
      en: "Hello",
    },
    loginTitle: {
      fr: "Se connecter",
      en: "Login",
    },
    delete: {
      fr: "Supprimer",
      en: "Delete",
    },
    buttonSave: {
      fr: "Sauvegarder",
      en: "Save",
    },
    buttonLogout: {
      fr: "Se déconnecter",
      en: "Logout",
    },
    loginDescription: {
      fr: "Veuillez vous authentifier afin de modifier vos informations.",
      en: "Please login in order to modify your information.",
    },
    pass: {
      fr: "Mot de passe",
      en: "Password",
    },
    passOublie: {
      fr: "Mot de passe oublié ?",
      en: "Forgot password?",
    },
  },

  "edit-information-login": {
    bonjour: {
      fr: "Bonjour",
      en: "Hello",
    },
    ressaye: {
      fr: "n’est associée à aucun compte. Veuillez réessayer",
      en: "is not associated with any account. Please try again",
    },
    topr: {
      fr: "Un mot de passe temporaire vient de vous être envoyé par mail à l’adresse",
      en: "A temporary password has just been sent to you by email to the address",
    },
    subtitle: {
      fr: "Veuillez saisir le nom d'utilisateur rattaché à votre compte.",
      en: "Please enter the username attached to your account",
    },
    retour: {
      fr: "Retour",
      en: "Back",
    },
    buttonSend: {
      fr: "Envoyer la demande",
      en: "Send request",
    },
    pleaseLogin: {
      fr: "Veuillez vous authentifier afin de modifier vos informations.",
      en: "Please login in order to modify your information.",
    },
    pass: {
      fr: "Mot de passe",
      en: "Password",
    },
    "pass-oublie": {
      fr: "Mot de passe oublié ?",
      en: "Forgot password?",
    },
  },

  identificationForm: {
    title: {
      fr: "Partager vos informations avec",
      en: "Share your information with",
    },
    name: {
      fr: "Prénom",
      en: "First name",
    },
    lastName: {
      fr: "Nom",
      en: "Last name",
    },
    button: {
      fr: "Partager",
      en: "Share",
    },
    partager: {
      fr: "Pressé(e)? Partagez une photo de votre carte de visite en quelques secondes",
      en: "In a hurry? Share a picture of your business card in seconds",
    },
    sending: {
      en: "Sending...",
      fr: "Envoi...",
      // Add other languages here
    },
  },

  mainButton: {
    showSettings: {
      fr: "Accéder aux Paramètres",
      en: "Go to Settings",
    },
    updateMyContact: {
      fr: "Mettre à jour ma fiche de contact",
      en: "Update my contact sheet",
    },
    addContact: {
      fr: "Ajouter aux contacts",
      en: "Add to contacts",
    },
    identifyContact: {
      fr: "Partager mes infos",
      en: "Share my information",
    },
  },

  qrCode: {
    secondeClick: {
      fr: "Vérifiez votre boite mail vos QR-Codes Hors ligne/En ligne  ont été envoyés",
      en: "Check your mail your Offline/Online QR-Codes have been sent",
    },
  },

  pwa: {
    title: {
      fr: "QR Code en ligne/hors ligne",
      en: "QR Code online/offline",
    },
    description: {
      fr: "Veuillez vous authentifier une fois afin de générer vos QR code online/offline. Ils seront accessibles les prochaines fois sans vous connecter.",
      en: "Please authenticate once in order to generate your online/offline QR code. They will be accessible the next time without logging in.",
    },
  },

  sectionTitle: {
    settings: {
      fr: "Paramètres",
      en: "Settings",
    },
    about: {
      fr: "À propos",
      en: "About",
    },
    contactInfo: {
      fr: "Coordonnées",
      en: "Contact Information",
    },
    socials: {
      fr: "Réseaux sociaux",
      en: "Social media",
    },
    products: {
      fr: "Produits et services",
      en: "Products and services",
    },
    appointment: {
      fr: "Prise de rendez-vous",
      en: "Appointment booking",
    },
    links: {
      fr: "Liens et pdfs",
      en: "Links and pdfs",
    },
    images: {
      fr: "Images",
      en: "Images",
    },
    videos: {
      fr: "Vidéos",
      en: "Videos",
    },
  },

  emptyCompo: {
    add: {
      fr: "ajouter",
      en: "add",
    },
    addPlural: {
      fr: "Ajouter vos",
      en: "Add your",
    },
  },

  logoutAnim: {
    title: {
      fr: "Pour vous reconnecter la prochaine fois, swipez cette zone à gauche",
      en: "To reconnect next time, swipe this area to the left",
    },

    button: {
      fr: "Ok, c’est noté !",
      en: "Okay, noted!",
    },
  },

  settings: {
    qrCode: {
      fr: "Obtenir mes QR Codes online/offline par mail",
      en: "Receive my QR codes online/offline through email.",
    },
    title: {
      fr: "Paramètres",
      en: "Settings",
    },
    "password-change-section": {
      title: {
        fr: "Redéfinir le mot de passe",
        en: "Change your password",
      },
      subtitle: {
        fr: "Merci de saisir votre ancien mot de passe avant d’en choisir un nouveau.",
        en: "Please enter your old password before choosing a new one.",
      },
      actuel: {
        fr: "Mot de passe actuel*",
        en: "Current password*",
      },
      nouveau: {
        fr: "Nouveau mot de passe*",
        en: "New password*",
      },
      confirmer: {
        fr: "Confirmer le nouveau mot de passe*",
        en: "Confrim new password*",
      },
      incorrect: {
        fr: "Mot de passe incorrect !",
        en: "Password incorrect!",
      },
      different: {
        fr: "Les mots de passe ne correspondent pas !",
        en: "Passwords don't match",
      },
      button: {
        fr: "Sauvegarder",
        en: "Save",
      },
    },
    "custom-link-section": {
      title: {
        fr: "Définir un lien public",
        en: "Set a public link",
      },
      lienactuel: {
        fr: "Lien actuel",
        en: "Current link",
      },
      partager: {
        fr: "Partager avec une rencontre",
        en: "Share with an encounters",
      },
      copier: {
        fr: "Copier l'URL",
        en: "Copy URL",
      },
      other: {
        fr: "Autres",
        en: "Other",
      },
      subtitle: {
        fr: "Transformer votre lien crypté en lien public vous permet d’atteindre une plus large audience",
        en: "Turning your encrypted link into a public link allows you to reach a wider audience",
      },
      lien: {
        fr: "Lien personnalisé",
        en: "Customized link",
      },
      error: {
        fr: "Ce lien est déjà pris!",
        en: "This link is already taken!",
      },
      success: {
        fr: "Lien disponible!",
        en: "This link is available!",
      },
      button: {
        fr: "Rétablir le lien",
        en: "Reset link",
      },
    },
    "temporary-code-section": {
      title: {
        fr: "Protéger la fiche contact avec un mot de passe temporaire.",
        en: "Protect the contact card with a temporary password.",
      },
      active: {
        fr: "La protection est activée.",
        en: "Protection activated.",
      },
      inactive: {
        fr: "La protection est désactivée.",
        en: "Protection inactive.",
      },
      subtitle: {
        fr: "Votre code temporaire",
        en: "Your temporary code",
      },
    },
  },

  appointment: {
    title: {
      fr: "Prise de rendez-vous",
      en: "Appointment booking",
    },
    subtitle: {
      fr: "Offrez la possibilité à vos rencontres de réserver un créneau disponible sur votre agenda en quelques clics",
      en: "Offer your encounters the possibility to reserve an available slot on your calendar in a few clicks",
    },
    how: {
      fr: "Tutoriel pour obtenir votre lien ",
      en: "How to get your  link",
    },
    appuyer: {
      fr: "Appuyer ici pour prendre un rendez-vous avec",
      en: "Click here to book an appointment with",
    },
  },

  footer: {
    title: {
      fr: " et des milliers d'autres professionnels utilisent Popcard tous les jours pour garder contact avec leurs rencontres.",
      en: " and thousands of other professionals use Popcard every day to keep in touch with the people they encounter.",
    },
    button: {
      fr: "Obtenir ma popcard",
      en: "Get my popcard",
    },
    reserved: {
      fr: "Tous droits réservés",
      en: "All rights reserved",
    },
    loginButton: {
      fr: "Mettre à jour mon profil",
      en: "Update my profile",
    }
  },

  productModal: {
    orderOn: {
      fr: "Commander sur:",
      en: "Order on:",
    },
  },

  mainCard: {
    title: {
      fr: "Souhaitez-vous modifier vos informations ?",
      en: "Would you like to modify your information?",
    },
  },

  uploaderPic: {
    upload: {
      fr: "Prendre une photo",
      en: "Take a picture",
    },
    telecharger: {
      fr: "Choisissez une image existante",
      en: "Choose an existing image",
    },
    annuler: {
      fr: "Supprimer l'image de couverture",
      en: "Delete cover image",
    },
    delete: {
      fr: "Supprimer l'image de profil",
      en: "Delete profile image",
    },
  },

  changeProfilePicture: {
    takepicture: {
      fr: "Prendre une photo",
      en: "Take a picture",
    },
    title1: {
      fr: "Choisissez une image existante",
      en: "Choose an existing image",
    },
    deleteProfilePic: {
      fr: "Supprimer l'image de profil",
      en: "Delete profile image",
    },
    deleteCover: {
      fr: "Supprimer l'image de couverture",
      en: "Delete cover image",
    },
  },

  cropImage: {
    title: {
      fr: "Recadrez votre photo pour un meilleur rendu",
      en: "Crop your photo for a better result",
    },

    button: {
      fr: "Terminer",
      en: "Finish",
    },
  },

  linkAndPdfs: {
    uploadFile: {
      en: "Upload your pdf",
      fr: "Téléchargez votre pdf",
    },
  },
};
