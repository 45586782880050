import { createSlice } from "@reduxjs/toolkit";

const appTranslationSlice = createSlice({
  name: "translation",
  initialState: {
    auth: {
      title: {
        en: "Select your setup Language",
        fr: "Sélectionner votre langue de paramétrage",
      },
      // title2: {
      //   en: "You can change your display language with one click",
      //   fr: "Vous pouvez changer votre langue d'affichage en un clic",
      // },
      title2: {
        fr: "Bienvenue sur",
        en: "Welcome to",
      },
      offered: {
        fr: "offerte par",
        en: "offered by",
      },
      subtitle2One: {
        fr: "Vous êtes l’heureux propriétaire d’une carte de visite nouvelle génération",
        en: "You are the proud owner of a new generation business card",
      },
      subtitle2Two: {
        fr: "Avec Popcard, partagez vos coordonnées et informations professionnelles avec vos rencontres en un seul geste.",
        en: "With Popcard, share your contact details and professional information with your meetings in a single gesture.",
      },
      title3: {
        fr: "Création de compte",
        en: "Create account",
      },
      subtitle3: {
        fr: "Saisissez un email professionnel et un mot de passe pour vos futures connexions à votre espace popcard",
        en: "Enter a professional email and password for your future connections to your popcard space",
      },
      password:{
        fr: "Mot de passe",
        en: "Password",
      },
      confirmPassword: {
        fr: "Confirmer le mot de passe",
        en: "Confirm password",
      },
      passwordNotMatching: {
        fr: "Les mots de passe ne correspondent pas",
        en: "Passwords do not match",
      },
      button: {
        en: "Continue",
        fr: "Continuer",
      },
      buttonTwo: {
        fr: "Démarrer la configuration",
        en: "Start configuration",
      },
      buttonNext: {
        fr: "Suivant",
        en: "Next",
      },
    },
    ConfirmEmail: {
      title: {
        fr: "Verification de mail",
        en: "Email verification",
      },
      subtitle: {
        fr: "Un email vient de vous être envoyée à l’adresse :",
        en: "An email has been sent to:",
      },
      subtitleSecond:{
        fr: "Un nouvel email vient de vous être envoyée à l'adresse :",
        en: "A new email has just been sent to:",
      },
      subtitle2: {
        fr: "Merci de vérifier votre boîte de réception (Principale/Spam) pour confirmer votre email et poursuivre le paramétrage de votre Popcard.",
        en: "Please check your inbox (Main/Spam) to confirm your email and continue setting up your Popcard.",
      },
      button: {
        fr: "Renvoyer le mail",
        en: "Resend email",
      },
    },
    onboardingDetails: {
      title: {
        en: "Contact card",
        fr: "Fiche de contact",
      },
      subtitle: {
        en: "Complete your profile information",
        fr: "Renseignez vos informations de profil",
      },
      firstName: {
        fr: "Prénom",
        en: "First name",
      },
      lastName: {
        fr: "Nom",
        en: "Last name",
      },
      company: {
        fr: "Entreprise",
        en: "Company",
      },
      position: {
        fr: "Intitulé de poste",
        en: "Position title",
      },
      mobileNumber: {
        fr: "Numéro mobile",
        en: "Mobile number",
      },
      addMobileNumber: {
        fr: "Ajouter un Numéro",
        en: "Add a Phone Number",
      },
      buttonNext: {
        fr: "Suivant",
        en: "Next",
      },
      buttonSave: {
        fr: "Enregistrer",
        en: "Save",
      },
    },
    ProfessionalInfo: {
      title: {
        fr: "Coordonnées",
        en: "Contact Information",
      },
      subtitle: {
        fr: "Saisissez les coordonnées professionnelles que vous souhaitez partager avec vos rencontres",
        en: "Enter the business contact information that you want to share with your encounters",
      },
      email: {
        fr: "Adresse email",
        en: "Email address",
      },
      webSite: {
        fr: "Site web",
        en: "Website",
      },
      adresse: {
        fr: "Adresse",
        en: "Address",
      },
      buttonNext: {
        fr: "Suivant",
        en: "Next",
      },
      buttonSave: {
        fr: "Enregistrer",
        en: "Save",
      },
    },
    About: {
      title: {
        fr: "À propos",
        en: "About",
      },
      subtitle: {
        fr: "Présentez-vous, parlez de vos projets et de vos passions… C’est votre première impression (digitale) sur vos rencontres !",
        en: "Introduce yourself, talk about your projects and your passions... This is your first (digital) impression on the people you encounter!",
      },
      aboutInput: {
        en: "Hello, …",
        fr: "Bonjour, …",
      },
      buttonNext: {
        fr: "Suivant",
        en: "Next",
      },
      buttonSave: {
        fr: "Enregistrer",
        en: "Save",
      },
    },
    Socials: {
      title: {
        fr: "Réseaux sociaux",
        en: "Social media",
      },
      subtitle: {
        fr: "Choisissez parmi une multitude de réseaux sociaux pour mettre en valeur ce qui compte le plus pour vous",
        en: "Choose from a multitude of social networks to showcase what matters most to you",
      },
      button: {
        fr: "Ajouter un réseau social",
        en: "Add a social network",
      },
      buttonNext: {
        fr: "Suivant",
        en: "Next",
      },
      buttonSave: {
        fr: "Enregistrer",
        en: "Save",
      },
    },
    ProductServices: {
      title: {
        fr: "Produits et services",
        en: "Products and services",
      },
      subtitle: {
        fr: "Mettez en valeur vos produits et services et listez l'ensemble des plateformes sur lesquelles vos rencontres peuvent les obtenir",
        en: "Highlight your products and services and list all the platforms on which your encounters can get them",
      },
      addProductButton: {
        fr: "Ajouter un nouveau produit/service",
        en: "Add a new product/service",
      },
      addProductModal: {
        titleAdd: {
          fr: "Ajouter un produit/service",
          en: "Add a product/service",
        },
        titleEdit: {
          fr: "Modifier un produit/service",
          en: "Edit a product/service",
        },
        name: {
          fr: "Nom du produit/service",
          en: "Product/service name",
        },
        prix: {
          fr: "Prix",
          en: "Price",
        },
        image: {
          fr: "Photos (3 max.)",
          en: "Photos (3 max.)",
        },
        addImageButton: {
          fr: "Uploader une image",
          en: "Upload a picture",
        },
        link: {
          fr: "Lien de vente",
          en: "Sales link",
        },
        button: {
          fr: "Ajouter un nouveau lien de vente",
          en: "Add a new sales link",
        },
        buttonSave: {
          fr: "Enregistrer",
          en: "Save",
        },
      },
      buttonNext: {
        fr: "Suivant",
        en: "Next",
      },
      buttonSave: {
        fr: "Enregistrer",
        en: "Save",
      },
    },
    Calendly: {
      title: {
        fr: "Prise de rendez-vous",
        en: "Appointment booking",
      },
      subtitle: {
        fr: "Offrez la possibilité à vos rencontres de réserver un créneau disponible sur votre agenda en quelques clics",
        en: "Offer your encounters the possibility to reserve an available slot on your calendar in a few clicks",
      },
      tutoriel: {
        fr: "Tutoriel pour obtenir votre lien ",
        en: "How to get your  link",
      },
      buttonNext: {
        fr: "Suivant",
        en: "Next",
      },
      buttonSave: {
        fr: "Enregistrer",
        en: "Save",
      },
    },
    Links: {
      title: {
        fr: "Liens et pdfs",
        en: "Links and pdfs",
      },
      subtitle: {
        fr: "Saisissez un lien vers un site ou un document hebergé pour permettre à vos rencontres d’y accéder en un clic",
        en: "Enter a link to a site or hosted document to allow your meetings to access it with one click",
      },
      addLink: {
        fr: "Ajouter un lien",
        en: "Add a link",
      },
      buttonNext: {
        fr: "Suivant",
        en: "Next",
      },
      buttonSave: {
        fr: "Enregistrer",
        en: "Save",
      },
    },
    Images: {
      title: {
        fr: "Images",
        en: "Images",
      },
      subtitle: {
        fr: "Exprimez votre personnalité et démontrez votre savoir-faire à travers cette gallerie d’images",
        en: "Express your personality and demonstrate your expertise through this gallery of images",
      },
      button: {
        fr: "Uploader une image",
        en: "Upload a picture",
      },
      buttonNext: {
        fr: "Suivant",
        en: "Next",
      },
      buttonSave: {
        fr: "Enregistrer",
        en: "Save",
      },
    },
    Videos: {
      title: {
        fr: "Vidéos",
        en: "Videos",
      },
      title2: {
        en: "You can change your display language with one click",
        fr: "Vous pouvez changer votre langue d'affichage en un clic",
      },
      subtitle: {
        fr: "Saisissez un lien vers les vidéos que vous souhaitez mettre en valeur auprès de vos rencontres",
        en: "Enter a link to the videos you want to share with your encounters",
      },
      button: {
        fr: "Ajouter un vidéo",
        en: "Add a video",
      },
      buttonNext: {
        fr: "Suivant",
        en: "Next",
      },
      buttonSave: {
        fr: "Enregistrer",
        en: "Save",
      },
      buttonFinish: {
        fr: "Terminer",
        en: "Finish",
      },
    },
  },
  reducers: {},
});

export const {} = appTranslationSlice.actions;

export default appTranslationSlice.reducer;
