import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import { useRegisterCollaboratorMutation } from "../../../redux/appSlices/onboarding/onboardingApiSlice";
import { setOnboardingStep } from "../../../redux/appSlices/collaborator/collaboratorSlice";

const StepThree = ({ authTranslation, lang, restriction }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userEmail = useSelector(
    (state) => state.collaborator.collaboratorInfo.email
  );
  const collaborator = useSelector(
    (state) => state.collaborator.collaboratorInfo
  );
  const userLang = useSelector(
    (state) => state.collaborator.collaboratorInfo.language
  );

  const [error, setError] = useState(false);
  const [safeToPass, setSafeToPass] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const [authData, setAuthData] = useState({
    collaborator_id: id,
    language: userLang,
  });

  useEffect(() => {
    if (password === confirmPassword) {
      setPasswordsMatch(true);
      setError(false);
    } else if (password !== confirmPassword && confirmPassword.length > 0) {
      setPasswordsMatch(false);
      setError(authTranslation?.passwordNotMatching?.[lang]);
    }
  }, [password, confirmPassword]);

  useEffect(() => {
    if (
      passwordsMatch &&
      password.length >= 6 &&
      confirmPassword.length >= 6 &&
      (userEmail || email.length > 0)
    ) {
      setSafeToPass(true);
      setError(false);
    } else {
      setSafeToPass(false);
    }
  }, [email, password, confirmPassword, passwordsMatch]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 10000);
    }
  }, [error]);

  useEffect(() => {
    if (safeToPass) {
      let restrictMail = false;
      if (
        restriction?.restrict_register_email_ext &&
        restriction?.register_email_ext
      ) {
        if (restriction?.register_email_ext.startsWith("@")) {
          restrictMail = `${email}${restriction?.register_email_ext}`;
        } else {
          restrictMail = `${email}@${restriction?.register_email_ext}`;
        }
      }
      setAuthData({
        ...authData,
        // email: userEmail ? userEmail.toLowerCase() : email.toLowerCase(),
        email: restrictMail ? restrictMail.toLowerCase() : email.toLowerCase(),
        password,
        confirm_password: confirmPassword,
      });
    }
  }, [safeToPass, email, password, confirmPassword, passwordsMatch]);

  const [registerCollaborator, { isLoading }] =
    useRegisterCollaboratorMutation();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await registerCollaborator({
        authData,
      })
        .unwrap()
        .then((res) => {
          // clear the localStorage first
          localStorage.clear();
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("collaborator_id", res.data.collaborator_id);
          dispatch(setOnboardingStep({ onboarding_step: 0 }));
        });
    } catch (error) {
      setError(
        error?.data?.message?.[lang] ? error?.data?.message?.[lang] : "Error"
      );
    }
  };

  return (
    <section className="fields-content ml-5 mr-5 pb-[200px]">
      <div className="field-title pt-20 text-2xl font-SteradianMedium">
        {authTranslation.title3?.[lang]}
      </div>
      <div className="field-description text-lg mt-4">
        {authTranslation.subtitle3?.[lang]}
      </div>
      {/* {error && (
        <div className="text-[#e63838] tracking-[-0.16px] leading-[20px] mt-[21px] text-[16px] ">
          🚫 {error}
        </div>
      )} */}
      {error && (
        <div className="text-[#e63838] tracking-[-0.16px] leading-[20px] mt-[21px] text-[16px] ">
          🚫 {error}
        </div>
      )}
      <form className="mt-10" onSubmit={handleSubmit}>
        <div className="single-onboarding-input">
          <label htmlFor="">Email</label>
          {userEmail && collaborator.confirmed ? (
            <div className="input-txt-disabled flex mb-4 mt-2">
              {userEmail}
              <img
                src="/assets/img/icons/lock.svg"
                alt=""
                srcSet=""
                className="ml-[10px]"
              />
            </div>
          ) : (
            <div className="flex items-center relative mb-4 mt-2">
              <input
                type={
                  restriction?.restrict_register_email_ext &&
                  restriction?.register_email_ext
                    ? "text"
                    : "email"
                }
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                className="bg-popcard border border-[#ccb7b7] text-gray-900 text-sm rounded-lg block w-full p-3"
                placeholder=""
                id="email"
              />
              {restriction?.restrict_register_email_ext && (
                <span
                  className="bg-[#fff3e5] border border-[#ccb7b7] p-3 px-5 absolute right-0 rounded-r-lg"
                  id="mail_ext"
                >
                  @
                  {restriction?.register_email_ext.startsWith("@")
                    ? restriction?.register_email_ext.split("@")[1]
                    : restriction?.register_email_ext}
                </span>
              )}
            </div>
          )}
        </div>
        <div className="relative">
          {/* <label htmlFor="">{onboarding_trans["password-"][lang]}</label> */}
          <label htmlFor="">
            {authTranslation.password?.[lang]} (6 car. min)
          </label>
          <input
            type={passwordVisible ? "text" : "password"}
            name="password"
            onChange={(e) => setPassword(e.target.value)}
            className="bg-popcard border border-[#ccb7b7] text-gray-900 text-sm rounded-lg block w-full p-3 mb-4 mt-2 relative"
            placeholder=""
            id="password"
          />
          <div
            className="absolute top-[68%] translate-y-[-50%] right-[10px]"
            onClick={togglePasswordVisibility}
          >
            {passwordVisible ? (
              <RiEyeOffLine className="text-[#795656]" />
            ) : (
              <RiEyeLine className="text-[#795656]" />
            )}
          </div>
        </div>
        <div className="relative">
          {/* <label htmlFor="">{onboarding_trans["password-confirm"][lang]}</label> */}
          <label htmlFor="">
            {authTranslation.confirmPassword?.[lang]} (6 car. min)
          </label>
          <input
            type={passwordVisible ? "text" : "password"}
            name="confirm_password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="bg-popcard border border-[#ccb7b7] text-gray-900 text-sm rounded-lg block w-full p-3 mb-4 mt-2"
            placeholder=""
            id="confirmPassword"
          />
          <div
            className="absolute top-[68%] translate-y-[-50%] right-[10px]"
            onClick={togglePasswordVisibility}
          >
            {passwordVisible ? (
              <RiEyeOffLine className="text-[#795656]" />
            ) : (
              <RiEyeLine className="text-[#795656]" />
            )}
          </div>
        </div>

        <div className="button-container" style={{ zIndex: 0 }}>
          <button
            className={`btn-fields ${safeToPass ? "btn-active" : null}`}
            id="btn-next"
            type="submit"
            disabled={!safeToPass}
          >
            {authTranslation.buttonNext?.[lang]}
          </button>
        </div>
      </form>
    </section>
  );
};

export default StepThree;
