import React, { useEffect, useState, createContext } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useParams,
  useNavigate,
} from "react-router-dom";

import MainLoading from "./componants/MainLoading";

import "./App.css";
import Profile from "./views/Profile";
import Login from "./views/auth/Login";
// import Login from "./views/x-Login";

// import TestCompo from "./componants/TestCompo";
import Onboarding from "./views/onBoarding/Onboarding";
import VerifyEmail from "./views/VerifyEmail";
import Loading from "./componants/Loading";
import SettingQrCode from "./componants/profile/ShowSetting/SettingQrCode";

function RedirectLogin() {
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      return navigate("/profile/" + id);
    }
  }, []);
  return null;
}

function App() {
  return (
    <div className="App min-h-[100vh] bg-popcard font-SteradianRegular">
      <BrowserRouter>
        <Routes>
          <Route element={<MainLoading />} exact path="/" />
          <Route element={<MainLoading />} path="/:id" />

          {/* <Route element={<Home />} path="/onboarding/:id" /> */}
          <Route element={<Onboarding />} path="/onboarding/:id" />
          <Route element={<Profile />} path="/profile" />
          <Route element={<Profile />} path="/profile/:id" />

          <Route element={<VerifyEmail />} path="/confirm/:id" />

          <Route element={<Login />} path="/login" />
          <Route element={<RedirectLogin />} path="/login/:id" />

          {/* <Route element={<ForgetPassword />} path="/forget-password" /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
