import React, { useEffect, useState } from "react";
import "../../styles/LanguageSelector.scss";
import EnglishFlag from "../../assets/england.svg";
import FrenchFlag from "../../assets/france.svg";
import { useDispatch } from "react-redux";
import { setLang as setReduxLang } from "../../redux/appSlices/collaborator/collaboratorSlice";

const LanguageSelector = () => {
  const dispatch = useDispatch()
  const [lang, setLang] = useState("fr");
  const [selectedLanguage, setSelectedLanguage] = useState(
    lang === "en" ? "English" : "Francais"
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
      dispatch(setReduxLang(lang))
  }, [lang])

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
    setIsDropdownOpen(false);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (language) => {
    setSelectedLanguage(language);
    if (language === "English") {
      setLang("en");
    } else {
      setLang("fr");
    }
    localStorage.setItem('lang', lang)


    setIsDropdownOpen(false);
  };



  return (
    <div className="dropdown-container">
      <div className="custom-dropdown">
        <div
          className="custom-dropdown__selected-item"
          onClick={handleDropdownToggle}
          style={{ backgroundColor: "#FFFAF5" }}
        >
          <img
            src={selectedLanguage === "English" ? EnglishFlag : FrenchFlag}
            alt={`${selectedLanguage} Flag`}
            className="custom-dropdown__flag"
          />
          <span className="custom-dropdown__language">{selectedLanguage}</span>
          <span className="custom-dropdown__arrow text-primaryP text-[13px]">
            {isDropdownOpen ? "▲" : "▼"}
          </span>
        </div>
        {isDropdownOpen && (
          <div
            className="custom-dropdown__dropdown"
            style={{ backgroundColor: "#FFF3E7" }}
          >
            {selectedLanguage !== "English" && (
              <div
                className="custom-dropdown__option"
                onClick={() => handleOptionClick("English")}
              >
                <img
                  src={EnglishFlag}
                  alt="English Flag"
                  className="custom-dropdown__flag"
                />
                <span className="custom-dropdown__language">English</span>
              </div>
            )}
            {selectedLanguage !== "Francais" && (
              <div
                className="custom-dropdown__option"
                onClick={() => handleOptionClick("Francais")}
              >
                <img
                  src={FrenchFlag}
                  alt="French Flag"
                  className="custom-dropdown__flag"
                />
                <span className="custom-dropdown__language">Français</span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default LanguageSelector;
