import React, { useEffect, useState } from "react";

import "../../styles/notificationpopup.scss";
import { BsArrowRight } from "react-icons/bs";
import { FaCameraRetro } from "react-icons/fa";
import closesvg from "../../assets/closesss.svg";
import bgSvg from '../../assets/bgNotification.svg'

import { translation } from "../../translation";

import PhonePicker from "../PhonePicker";

import IdentificationFormDone from "./IdentificationFormDone";

import { useParams } from "react-router";
// import useTracking from "../../hooks/useTracking";
import {
  completerFormSchema,
  validateCompleterForm,
} from "../Validations/validatecompleter";
import { completerPhoneSchema } from "../Validations/validatePhonecompleter";
import { useSelector } from "react-redux";
import useCollaboratorRestriction from "../../hooks/useCollaboratorRestriction";
import { usePatchCreateIdentifMutation, useUploadPublicMutation } from "../../redux/appSlices/collaborator/collaboratorApiSlice";

const errorsSchema = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
};

const IdentificationForm = ({
  show,
  close,
  handleClick,
  traking,
  resId,
  dataProfile,
  dataRes,
}) => {

  const [errors, setErrors] = useState({});
  // const [image, setImage] = useState(null);
  const [error, setError] = useState(null);

  const [stream, setStream] = useState(null);
  const [open, setOpen] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [uploadPercentStr, setUploadPercentStr] = useState("0%");
  const [done, setDone] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);


  const restriction = useCollaboratorRestriction();
  const [patchCreateIdentif] = usePatchCreateIdentifMutation()
  const [uploadPublic] = useUploadPublicMutation()

  const lang = useSelector((state) => state.collaborator.collaboratorInfo.language);
  const collaborator = useSelector((state) => state.collaborator.collaboratorInfo);

  const [completer, setcompleter] = useState({
    card: "",
    email: "",
    first_name: "",
    last_name: "",
    phone: "+212-",
    identified_in: collaborator._id,
    updated_by_user: true,
    tracking: localStorage.getItem('tracking'),
    user_token: localStorage.getItem('user_token'),
    identification_methode: 0,
  });

  useEffect(() => {
    // After a short delay, set isVisible to true to trigger the transition
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 500); // Adjust the delay as needed

    return () => clearTimeout(timeout);
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setcompleter((prev) => {
      if (name === "prefix" || name === "phone") {
        const existingUrlParts = prev.phone.split("-");
        const prefix = name === "prefix" ? value : existingUrlParts[0];
        const phone = name === "phone" ? value : existingUrlParts[1];
        return {
          ...prev,
          phone: `${prefix || ""}-${phone || ""}`,
        };
      } else {
        return {
          ...prev,
          [name]: value,
        };
      }
    });
  };

  useEffect(() => {
    if (!open) {
      setStream(null);
    }
  }, [open]);

  function openCamera() {
    if (open) return;
    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: "environment" } })
      .then((s) => {
        setStream(s);
        setOpen(true);
        setShowIcon(true);
      })
      .catch((err) => setError(err));
  }

  function closeCamera() {
    if (!stream) return;
    const tracks = stream.getTracks();
    tracks.forEach((track) => track.stop());
    setStream(null);
    setOpen(false);
  }

  function handleTakePicture() {
    if (!stream) return;
    const video = document.querySelector("video");
    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    canvas.toBlob((blob) => {
      const file = new File([blob], "picture.jpg", {
        type: "image/jpeg",
      });

      upload(file);
    });

    setOpen(false);
    setShowIcon(false);
    closeCamera();
  }

  const upload = async (file) => {
    if (uploadPercent > 0) return;
    if (!file) return;
    try {
      uploadPublic(file).unwrap().then((res) => {

        // setImage(res.data.file);

        patchCreateIdentif({
          identifId: dataRes._id,
          content: {
            ...completer,
            card: res.data.file
          }
        }).unwrap()
        // .then((res) => {});
        setDone(true);
      });
    } catch (error) {
      console.error(error);
    }
  }

  const handleClose = () => {
    close(false);
    setDone(false);
  };

  const validateForm = async () => {
    try {
      if (restriction?.procpect_identification === 0) {
        await completerFormSchema.validate(completer);
      } else {
        await completerPhoneSchema.validate(completer);
      }
      return true;
    } catch (error) {
      setErrors((prev) => ({
        ...prev,
        [error.path]: error.message,
      }));
      return false;
    }
  };

  const handleSubmit = async () => {
    const commonContent = {
      identifId: dataRes._id,
      content: {
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
      },
    };

    setErrors({ ...errorsSchema });

    try {
      const isValid = await validateForm();

      if (isValid) {
        await patchCreateIdentif({
          ...commonContent,
          content: {
            ...commonContent.content,
            ...completer
          },
        }).unwrap();
        setDone(true);
        setcompleter(commonContent.content);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return (
    <div className="bg-black bg-opacity-30 h-[100vh] fixed top-0 z-[1000] max-w-[580px] w-full ml-[-20px]">
      <div
        className={`fixed w-full max-w-[580px] bg-white bg-no-repeat bg-contain bg-bottom rounded-t-[30px] h-[550px] z-[1000] ${isVisible ? 'bottom-0 transition-all duration-300 ease-in-out opacity-100' : 'bottom-[-450px] opacity-0'}`}
        style={{ backgroundImage: `url(${bgSvg})` }}
      >
        <img
          src={closesvg}
          className="cursor-pointer absolute h-[15px] w-[15px] right-[20px] top-[20px] object-cover"
          alt="hello"
          onClick={handleClose}
        />
        {!done ? (
          <div className="flex flex-col items-center mt-[10px] p-[30px] w-full h-full">
            <div className="flex items-center justify-center">
              <div className="h-[60px] w-[60px] object-cover rounded-[50%] overflow-hidden">
                <img
                  src={
                    collaborator.profile_picture
                      ? `${process.env.REACT_APP_IMAGE_PATH}/${collaborator.profile_picture}`
                      : "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"
                  }
                  alt="avatar"
                  className="h-full w-full object-cover"
                />
              </div>


              <p className="text-black text-[18px] ml-[20px]">
                {translation?.identificationForm?.title?.[lang]}{" "}
                {collaborator.first_name}
              </p>
            </div>
            <div className="flex flex-col justify-between">
              <div className="inputWrapper">
                <input
                  type="text"
                  placeholder={
                    translation?.identificationForm?.name?.[lang]
                  }
                  className={`bg-white mt-[20px] border border-[#ccb7b7] rounded-[15.434px] text-black text-[16px] px-[20px] py-[10px] w-full outline-none ${errors.first_name ? 'border-[#ff4343]' : 'border-[#ccb7b7]'}`}
                  name="first_name"
                  onChange={handleChange}
                  value={completer?.first_name}
                />

                {errors.first_name && (
                  <p className="text-[#ff4343]">{errors.first_name}</p>
                )}
              </div>
              <div className="inputWrapper">
                <input
                  type="text"
                  placeholder={
                    translation?.identificationForm?.lastName?.[lang]
                  }
                  className={`bg-white mt-[20px] border border-[#ccb7b7] rounded-[15.434px] text-black text-[16px] px-[20px] py-[10px] w-full outline-none ${errors.last_name ? 'border-[#ff4343]' : 'border-[#ccb7b7]'}`}
                  name="last_name"
                  onChange={handleChange}
                  value={completer?.last_name}
                />

                {errors.last_name && (
                  <p className="text-[#ff4343]">{errors.last_name}</p>
                )}
              </div>
              <div className="inputWrapper">
                <input
                  type={
                    restriction?._id === "6474def0c01346262758c0d1"
                      ? "text"
                      : "email"
                  }
                  placeholder={
                    restriction?._id === "6474def0c01346262758c0d1"
                      ? "Besoin"
                      : "Email"
                  }
                  className={`bg-white mt-[20px] border border-[#ccb7b7] rounded-[15.434px] text-black text-[16px] px-[20px] py-[10px] w-full outline-none ${errors.email ? 'border-[#ff4343]' : 'border-[#ccb7b7]'}`}
                  name="email"
                  onChange={handleChange}
                  value={completer?.email}
                />

                {errors.email && <p className="text-[#ff4343]">{errors.email}</p>}
              </div>
              <div className="inputWrapper">
                <PhonePicker
                  onChange={handleChange}
                />

                {errors.phone && <p className="text-[#ff4343]">{errors.phone}</p>}
              </div>
              <div
                className="w-[180px] h-[50px] rounded-[6px] text-center text-[13px] bg-black text-white flex items-center justify-center my-[20px] mx-auto cursor-pointer"
                onClick={handleSubmit}
              disabled={loading}
              >
                {/* {loading
                    ? translation?.identificationForm?.sending?.[lang]
                    : translation?.identificationForm?.button?.[lang]} */}
                <p>{translation?.identificationForm?.button?.[lang]}</p>
              </div>
              {restriction?.procpect_identification === 1 && (
                <div className="flex items-center cursor-pointer" onClick={openCamera}>
                  <p className="text-black text-[14px] font-[600] w-fit">
                    {translation?.identificationForm?.partager?.[lang]}
                  </p>
                  <BsArrowRight className="icon" />
                </div>
              )}
            </div>
            <div className="Box">
              {showIcon ? (
                <FaCameraRetro
                  className="camera-icon z-[999]"
                  onClick={handleTakePicture}
                />
              ) : (
                ""
              )}

              <input
                type="file"
                className="hidden"
                onChange={(e) => upload(e.target.files[0])}
              />
              <div className="camera">
                {open ? (
                  <video
                    autoPlay
                    playsInline
                    muted
                    ref={(ref) => {
                      if (ref) {
                        ref.srcObject = stream;
                      }
                    }}
                    className="z-[998]"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="NotificationPopup__content">
            <IdentificationFormDone close={handleClose} data={collaborator} />
          </div>
        )}
      </div>
    </div>
  );
};

export default IdentificationForm;
