import React, { useState } from 'react'
// import { RiDeleteBin6Line } from 'react-icons/ri'


function ShowVideo({ data, index, lang, handleChange, handleDelete }) {

  const [openList, setOpenList] = useState(false)

  const videosList = [
    {
      value: 1,
      label: 'YouTube',
      icon: '/assets/img/icons/youtube.svg'
    },
    {
      value: 2,
      label: 'Vimeo',
      icon: '/assets/img/icons/vimeo.svg'
    },

  ]

  return (
    <div className='mt-7'>
      <div className='flex justify-between'>
        {/* <div>
          <select className="bg-labelsBg border border-labels rounded-lg py-2 px-5" name="type" onChange={(e) => handleChange(e, index)}>
            <option value={1}>YouTube</option>
            <option value={2}>Vimeo</option>
          </select>
        </div> */}
        <div>
          <div
            className='relative flex items-center justify-between w-[122px] h-[44px] bg-labelsBg border border-labels rounded-lg py-2 px-5 cursor-pointer'
            onClick={() => setOpenList(!openList)}
          >
            <p>{videosList[data.type - 1].label}</p>
            <img className={`w-[11px] h-[11px] ${openList ? 'rotate-180' : ''} `} src="../../assets/img/icons/arrow-down.svg" alt="show option" />
          </div>
          {
            openList && (
              <div className='absolute flex flex-col items-stretch w-[230px] h-[96px] bg-[#fff3e7] border border-[#ccb7b7] rounded-[4px] p-[13px] z-20'>
                {videosList.map((videoList) => (
                  <>
                    <div
                      className='flex gap-[21px] cursor-pointer'
                      key={videoList.value}
                      onClick={(e) => {
                        // console.log('videoList.value', videoList.value);
                        setOpenList(!openList)
                        handleChange(e, index, videoList.value);
                      }}
                    >
                      <img src={videoList.icon} />
                      <p className='text-[18px] leading-[25px] tracking-[-.73px]'>{videoList.label}</p>
                    </div>
                    <div className='border-b-[.7px] border-[#ccb7b7] my-[8px]' />
                  </>

                ))}
              </div>
            )
          }
        </div>
        <div
          className='cursor-pointer'
          onClick={() => handleDelete(data._id)}>
          <img src="../assets/img/icons/delete.svg" alt="delete video" />
        </div>
        {/* // <RiDeleteBin6Line size={30} onClick={() => handleDelete(data._id)}/> */}
      </div>
      <input className='mt-6 w-full text-lg text-black bg-popcard border-b border-inputBottom focus:outline-none' name='url' placeholder='URL*' defaultValue={data.url} onChange={(e) => handleChange(e, index)} />
      <input className='mt-4 w-full text-lg text-black bg-popcard border-b border-inputBottom h-9 focus:outline-none' name='description' placeholder='Description' defaultValue={data.description?.[lang]} onChange={(e) => handleChange(e, index)} />
    </div>
  )
}

export default ShowVideo