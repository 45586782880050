import React, { useState } from "react";
import { useSelector } from "react-redux";
import { usePatchCollaboratorMutation } from "../../redux/appSlices/onboarding/onboardingApiSlice";
import { setOnboardingStep } from "../../redux/appSlices/collaborator/collaboratorSlice";
import useCollaboratorRestriction from "../../hooks/useCollaboratorRestriction";
import { translation } from "../../translation";

const About = ({ edit, setOpenEditPopup }) => {
  const AboutTranslation = useSelector((state) => state.translation.About);

  const lang = useSelector(
    (state) => state.collaborator.collaboratorInfo.language
  );

  const collaborator = useSelector(
    (state) => state.collaborator.collaboratorInfo
  );

  const restriction = useCollaboratorRestriction();

  const [about, setAbout] = useState("");

  const [patchCollaborator, { isLoading }] = usePatchCollaboratorMutation();

  // ############### DEV AREA
  // ######################
  // ########################################################

  const [safe, setSafe] = useState(true);
  const [loading, setSLoading] = useState();

  const HandleChange = (e) => {
    setAbout(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      patchCollaborator({
        id: collaborator._id,
        content: {
          about: { [lang]: about },
          ...(edit ? {} : { onboarding_step: 4 }),
        },
      }).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  // ########################################################
  // ######################
  // ############### END DEV AREA

  return (
    <>
      {collaborator.restriction?.show_about ||
      !collaborator.restriction?.restrict_about ? (
        <section className="pt-[7vh] mx-5 pb-[120px]">
          <div className="font-SteradianMedium text-2xl">
            {translation?.sectionTitle?.about?.[lang]}
          </div>
          <div className="py-5 text-lg">{AboutTranslation.subtitle?.[lang]}</div>
          <div>
            {restriction?.restrict_about ? (
              <p className="w-full border border-inputBottom bg-popcard p-3 mt-5 rounded-md h-[364px] overflow-scroll">
                {restriction?.about?.[lang]}
              </p>
            ) : (
              <textarea
                name="about"
                id="about"
                className="w-full border border-inputBottom bg-popcard p-3 mt-5 rounded-md"
                placeholder={AboutTranslation.aboutInput?.[lang]}
                rows={5}
                defaultValue={collaborator.about?.[lang]}
                onChange={HandleChange}
              />
            )}
          </div>
          <div className="button-container">
            {!collaborator.restriction?.restrict_about || !edit ? (
              <button
                className={
                  safe || collaborator?.restriction?.restrict_about
                    ? "btn-fields btn-active"
                    : "btn-fields"
                }
                id="btn-next"
                onClick={
                  edit
                    ? (e) => {
                        handleSubmit(e);
                        setOpenEditPopup(false);
                      }
                    : handleSubmit
                }
              >
                {edit ? (
                  <img
                    src="/assets/img/loaders/loading.gif"
                    style={{
                      display: loading ? undefined : "none",
                      padding: "2px",
                    }}
                    width="23px"
                    alt=""
                    className="ml-[45%]"
                  />
                ) : null}
                {loading
                  ? null
                  : edit
                  ? AboutTranslation.buttonSave[lang]
                  : AboutTranslation.buttonNext[lang]}
              </button>
            ) : null}
          </div>
        </section>
      ) : null
    }
    </>
  );
};

export default About;
