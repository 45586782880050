import React from "react";
// import { useGlobalState } from "../..";
import thanksIcon from "../../assets/merci.svg";
import { useSelector } from "react-redux";

const IdentificationFormDone = ({ close, data }) => {
  // const [lang] = useGlobalState("lang_tran");
  const lang = useSelector(
    (state) => state.collaborator.collaboratorInfo.language
  );

  const merci = {
    fr: "Merci !",
    en: "Thank you !",
  };

  const merciText = {
    fr: "Vos informations ont été transmises à",
    en: "Your information has been sent to",
  };

  const avec = {
    fr: "avec succès.",
    en: "successfully.",
  };

  const button = {
    fr: "Fermer la fenêtre",
    en: "Close the window",
  };

  return (
    <div className="flex items-center flex-col justify-center mt-[3rem]">
      <img src={thanksIcon} alt="merci" className="object-contain w-[150px]" />
      <h6 className="text-[45px] font-bold">{merci[lang]}</h6>
      <p className="items-center text-center mt-2 font-semibold">
        {merciText[lang]} {data.first_name} {avec[lang]}
      </p>
      <div className="buttonWrapper mt-[2rem]">
        <button className="btn btn-primary" onClick={close}>
          {button[lang]}
        </button>
      </div>
    </div>
  );
};

export default IdentificationFormDone;
