import React, { useState } from "react";
import { useSelector } from "react-redux";
import EditButton from "../EditButton";
import EditComponants from "../EditComponants";
import useLoggedStatus from "../../../hooks/useLoggedStatus";
import { translation } from "../../../translation";
import useCollaboratorRestriction from "../../../hooks/useCollaboratorRestriction";
import useTracking from "../../../hooks/useTracking";

function ShowInformation() {
  const collaborator = useSelector(
    (state) => state.collaborator.collaboratorInfo
  );
  const lang = useSelector(
    (state) => state.collaborator.collaboratorInfo.language
  );

  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [componantToEditId, setComponantToEditId] = useState(0);
  const initialEditId = 2;

  const logged = useLoggedStatus();
  const restriction = useCollaboratorRestriction();
  const { sendTracking } = useTracking();

  let showProfessionalEmail = collaborator?.professional_email;
  let showWebsite = collaborator?.website;
  let showAddress = collaborator?.address;

  if (restriction?.restrict_contact_email_ext) {
    const sectionFirst = collaborator?.professional_email.includes("@")
      ? collaborator?.professional_email.split("@")[0]
      : collaborator?.professional_email;
    showProfessionalEmail = `${sectionFirst}@${restriction?.contact_email_ext}`;
  }
  if (restriction?.restrict_website) {
    showWebsite = restriction?.website;
  }

  if (restriction?.restrict_address) {
    showAddress = restriction?.address;
  }

  const handleTracking = (title, url) => {
    sendTracking({
      action: 111,
      collaborator_id: collaborator?.collaborator_id,
      componant: "Details",
      url: url,
      title: title,
    });
  };

  return (
    <section className="mt-9">
      <div className="flex justify-between items-center mb-[3vh]">
        <h2 className="text-xl font-SteradianMedium">
          {translation?.sectionTitle?.contactInfo?.[lang]}
        </h2>
        {logged && (
          <EditButton
            setOpenEditPopup={setOpenEditPopup}
            initialEditId={initialEditId}
            setComponantToEditId={setComponantToEditId}
          />
        )}
      </div>
      <div className="mt-3">
        {showProfessionalEmail && (
          <div
            className="flex gap-5 border-b-[0.7px] border-[rgb(204,183,183)] pb-4 mt-4 items-center"
            onClick={() => handleTracking("Email", showProfessionalEmail)}
          >
            <img src="../assets/img/icons/email.svg" />
            <a
              href={`mailto:${showProfessionalEmail}`}
              className="flex w-full break-all"
            >
              <p className="text-lg text-black">{showProfessionalEmail}</p>
              <img
                className="ml-auto"
                src="../assets/img/icons/arrow-right.svg"
              />
            </a>
          </div>
        )}
        {showWebsite && (
          <div
            className="flex gap-5 border-b-[0.7px] border-[#ccb7b7] pb-4 mt-4 items-center"
            onClick={() => handleTracking("Website", showWebsite)}
          >
            <img src="../assets/img/icons/website.svg" />
            <a
              className="flex w-full break-all"
              href={
                showWebsite?.startsWith("http")
                  ? showWebsite
                  : `http://${showWebsite}`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="text-lg w-full break-words max-w-[450px] text-black">
                {showWebsite}
              </p>
              <img
                className="ml-auto"
                src="../assets/img/icons/arrow-right.svg"
              />
            </a>
          </div>
        )}
        {showAddress && (
          <div
            className="flex gap-5 pb-4 mt-4 items-center"
            onClick={() => handleTracking("Address", showAddress)}
          >
            <img src="../assets/img/icons/address.svg" />
            <a
              href={`https://www.google.com/maps/search/${showAddress}`}
              className="flex w-full"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="text-lg text-black tracking-[-.81px] leading-6 max-w-[450px]">
                {showAddress}
              </p>
              <img
                className="ml-auto"
                src="../assets/img/icons/arrow-right.svg"
              />
            </a>
          </div>
        )}
      </div>
      {logged && openEditPopup && (
        <EditComponants
          componantId={componantToEditId}
          setOpenEditPopup={setOpenEditPopup}
        />
      )}
    </section>
  );
}

export default ShowInformation;
