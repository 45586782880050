import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetCollaboratorQuery } from "../redux/appSlices/collaborator/collaboratorApiSlice";
import MainCard from "../componants/profile/MainCard";
import { useDispatch, useSelector } from "react-redux";
import { getCollaborator } from "../redux/appSlices/collaborator/collaboratorSlice";
import Loading from "../componants/Loading";
import ShowAbout from "../componants/profile/showSections/ShowAbout";
import ShowInformation from "../componants/profile/showSections/ShowInformation";
import ShowSocials from "../componants/profile/showSections/ShowSocials";
import ShowProducts from "../componants/profile/showSections/ShowProducts";
import ShowCalendly from "../componants/profile/showSections/ShowCalendly";
import ShowLinks from "../componants/profile/showSections/ShowLinks";
import ShowImages from "../componants/profile/showSections/ShowImages";
import ShowVideos from "../componants/profile/showSections/ShowVideos";
import Footer from "../componants/profile/Footer";
import useLoggedStatus from "../hooks/useLoggedStatus";
import AddToContact from "../componants/profile/AddToContactButton";
import AddToContactButton from "../componants/profile/AddToContactButton";
import TestShowImages from "../componants/profile/showSections/TestShowImages";
import Header from "../componants/profile/header/Header";
import LogoutAnimation from "../componants/LogoutAnimation";
import AddToContactButtonFloat from "../componants/profile/AddToContactButtonFloat";
import useTracking from "../hooks/useTracking";
// import usePwaNavigation from "../hooks/usePwaNavigation";
const Profile = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const logged = useLoggedStatus();
  // usePwaNavigation();
  const [hideLogout, setLogout] = useState(
    localStorage.getItem("logout") === "true" ? true : false
  );
  const { sendTracking } = useTracking();

  // ############### DEV AREA
  // ######################
  // ########################################################

  // ########################################################
  // ######################
  // ############### END DEV AREA

  const { data, isLoading } = useGetCollaboratorQuery(id);
  let collaborator_id;

  useEffect(() => {
    if (data) {
      document.title = `Popcard - ${data?.data?.collaborator?.first_name} ${data.data.collaborator?.last_name}`;
      collaborator_id = data?.data?.collaborator?._id;
      sendTracking({
        collaborator_id: data.data.collaborator.collaborator_id,
        action: 51,
      });

      const metaElement = document.querySelector('meta[name="description"]');
      const linkElement = document.querySelector(
        'link[rel="apple-touch-icon"]'
      );
      linkElement.href = `${process.env.REACT_APP_IMAGE_PATH}${data.data.collaborator.profile_picture}`;
      metaElement.content = `${data?.data?.collaborator?.first_name} ${data.data.collaborator?.last_name}`;
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      dispatch(getCollaborator(data.data.collaborator));
    }
  }, [data]);

  // const updateMetaDescription = (description) => {
  //   const metaElement = document.querySelector('meta[name="description"]');
  //   if (metaElement) {
  //     metaElement.content = description;
  //   }
  // };

  if (isLoading) {
    return <Loading />;
  }

  if (!data && !isLoading) {
    window.location.href = "https://www.ocpgroup.ma/";
  }

  if (data && !data.data.collaborator.active) {
    window.location.href = "https://www.ocpgroup.ma/";
  }

  const ProfileSections = [
    <ShowAbout />,
    <ShowInformation />,
    <ShowSocials />,
    <ShowProducts />,
    <ShowCalendly />,
    <ShowLinks />,
    <TestShowImages />,
    <ShowVideos />,
  ];

  return (
    <div className="relative max-w-[580px] m-auto overflow-x-hidden">
      {hideLogout && (
        <LogoutAnimation
          onHide={() => {
            setLogout(false);
            localStorage.setItem("logout", "false");
          }}
        />
      )}
      {/* <Header /> */}
      <Header />
      <main className="mx-[20px]">
        <MainCard />
        {/* need the butto add to contact or update setting if logged here */}
        <AddToContactButton />

        {/* need the butto add to contact or update setting if logged here */}
        {/* <ShowAbout /> */}
        {/* <CleanShowAbout /> */}

        {/* <ShowAboutRestrictionTest />
        <ShowInformation />
        <ShowSocials />
        <ShowProducts />
        <ShowCalendly />
        <ShowLinks />
        <TestShowImages /> */}

        <div id="show-btn" className="fixed w-full z-[9999]" />

        {!logged && <AddToContactButtonFloat />}

        {data.data.collaborator.element_order.map((order) => {
          return (
            <div key={order?.toString()}>{ProfileSections[order - 1]}</div>
          );
        })}

        {/* <ShowImages /> */}
        {/* <ShowVideos /> */}
      </main>
      <Footer />
    </div>
  );
};

export default Profile;
