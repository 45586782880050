import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { usePatchCollaboratorMutation } from "../../redux/appSlices/onboarding/onboardingApiSlice";
import { AiOutlinePlusCircle } from "react-icons/ai";
import SingleLink from "../UI/SingleLink";
import { v4 as uuidv4 } from "uuid";
import { useUploadImageMutation } from "../../redux/appSlices/collaborator/collaboratorApiSlice";
import useCollaboratorRestriction from "../../hooks/useCollaboratorRestriction";

const Links = ({ edit, setOpenEditPopup }) => {
  const LinksTranslation = useSelector((state) => state.translation.Links);
  const lang = useSelector(
    (state) => state.collaborator.collaboratorInfo.language
  );
  const collaborator = useSelector(
    (state) => state.collaborator.collaboratorInfo
  );

  const restriction = useCollaboratorRestriction();

  const [patchCollaborator, { isLoading }] = usePatchCollaboratorMutation();
  const [uploadImage, data] = useUploadImageMutation();

  const [links, setLinks] = useState(collaborator.links);
  const [safe, setSafe] = useState(true);
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (restriction.restrict_links) {
      setLinks(restriction.links);
    }
  }, [restriction]);

  const SafeCheck = () => {
    const filter = links.map((link) => {
      if (!link?.url || link?.url?.length === 0) {
        return false;
      } else if (!link?.title?.[lang] || link?.title?.[lang]?.length === 0) {
        return false;
      } else {
        console.log(link?.title?.[lang]?.length);
        return true;
      }
    });
    return filter;
  };

  useEffect(() => {
    const filter = SafeCheck();
    if (filter.includes(false)) {
      setSafe(false);
    } else {
      setSafe(true);
    }
  }, [links]);

  const handleAdd = () => {
    setLinks([
      ...links,
      {
        _id: uuidv4(),
        description: {},
        is_admin: false,
        thumb: "",
        title: {},
        url: "",
        type: "url",
      },
    ]);
  };

  const handleChange = (e, index) => {
    const newlinks = [...links];
    if (e.target.name === "title" || e.target.name === "description") {
      newlinks[index] = {
        ...newlinks[index],
        [e.target.name]: { [lang]: e.target.value },
      };
    } else if (e.target.name === "thumb") {
      uploadImage(e.target.files[0])
        .then((result) => {
          newlinks[index] = {
            ...newlinks[index],
            thumb: result.data.data.imagePath,
          };
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      newlinks[index] = {
        ...newlinks[index],
        [e.target.name]: e.target.value,
      };
    }

    setLinks(newlinks);
  };

  const handleTypeChange = (index, linktype) => {
    const newlinks = [...links];
    newlinks[index] = {
      ...newlinks[index],
      type: linktype,
    };

    setLinks(newlinks);
  };

  const handleUpdatelink = (id, fileName) => {
    setLinks((prevLinks) =>
      prevLinks.map((link) =>
        link._id === id ? { ...link, url: fileName } : link
      )
    );
  };

  const handleImageUpload = async (e, index) => {
    try {
      const newLinks = [...links];

      const result = await uploadImage(e.target.files[0]);

      newLinks[index] = {
        ...newLinks[index],
        thumb: result.data.data.imagePath,
      };

      setLinks(newLinks);

      await patchCollaborator({
        id: collaborator._id,
        content: { links: newLinks },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleLinkImageDelete = (imagePath) => {
    // handling dlete image path
  };

  const handleDelete = (id) => {
    const newLink = links.filter((item, i) => item._id !== id);
    setLinks(newLink);
  };

  const handleSubmit = () => {
    try {
      patchCollaborator({
        id: collaborator._id,
        content: { links, ...(edit ? {} : { onboarding_step: 8 }) },
      }).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className="pt-[7vh] mx-5 pb-[120px]">
      <div>
        <div className="text-2xl font-SteradianMedium">
          {LinksTranslation.title[lang]}
        </div>
        <div className="text-lg mt-5">{LinksTranslation.subtitle[lang]}</div>
        <div className="mt-20">
          {links.map((link, i) =>
            restriction.restrict_links ? (
              <div className="flex gap-[14px] mt-20">
                <div className="h-[151px] w-[106px]">
                  <img
                    src={`${process.env.REACT_APP_IMAGE_PATH}${link?.thumb}`}
                    className="rounded-lg h-full w-full object-cover"
                  />
                </div>
                <div className="text-[18px] break-words max-w-[300px]">
                  <p>{link?.url}</p>
                  <p>{link?.title?.[lang]}</p>
                  <p>{link?.description?.[lang]}</p>
                </div>
                <img
                  src="../../assets/img/icons/lock.svg"
                  className="ml-auto w-[15px] h-[15px]"
                />
              </div>
            ) : (
              <SingleLink
                data={link}
                lang={lang}
                index={i}
                key={link._id}
                handleChange={handleChange}
                handleDelete={handleDelete}
                handleImageUpload={handleImageUpload}
                handleTypeChange={handleTypeChange}
                handleUpdatelink={handleUpdatelink}
              />
            )
          )}
        </div>

        {!restriction.restrict_links && (
          <div
            className="mt-5 flex items-center text-labels text-lg font-SteradianMedium gap-2 cursor-pointer"
            onClick={handleAdd}
          >
            <AiOutlinePlusCircle />
            {LinksTranslation.addLink[lang]}
          </div>
        )}
      </div>

      <div className="button-container">
        <button
          className={safe ? "btn-fields btn-active" : "btn-fields"}
          id="btn-next"
          onClick={
            edit
              ? (e) => {
                  handleSubmit(e);
                  setOpenEditPopup(false);
                }
              : handleSubmit
          }
          disabled={!safe}
        >
          {edit ? (
            <img
              src="/assets/img/loaders/loading.gif"
              style={{
                display: loading ? undefined : "none",
                padding: "2px",
              }}
              width="23px"
              alt=""
              className="ml-[45%]"
            />
          ) : null}
          {loading
            ? null
            : edit
            ? LinksTranslation?.buttonSave[lang]
            : LinksTranslation?.buttonNext[lang]}
        </button>
      </div>
    </section>
  );
};

export default Links;
