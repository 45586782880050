import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import EditButton from "../EditButton";
import EditComponants from "../EditComponants";
import useLoggedStatus from "../../../hooks/useLoggedStatus";
import { translation } from "../../../translation";
import useOutsideClosePopup from "../../../hooks/useOutsideClosePopup";

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


import { Pagination, Mousewheel, Keyboard, Navigation } from 'swiper/modules';
import useCollaboratorRestriction from "../../../hooks/useCollaboratorRestriction";
import EmptyContent from "../EmptyContent";
import useTracking from "../../../hooks/useTracking";

const ShowProducts = () => {

  const wrapperRef = useRef();

  const collaborator = useSelector(
    (state) => state.collaborator.collaboratorInfo
  );
  const lang = useSelector(
    (state) => state.collaborator.collaboratorInfo.language
  );

  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [componantToEditId, setComponantToEditId] = useState(0);
  const initialEditId = 4;

  const [openProduct, setOpenProduct] = useState(false)
  const [currentProduct, setCurrentProduct] = useState(-1)

  const logged = useLoggedStatus();
  const restriction = useCollaboratorRestriction();
  const { sendTracking } = useTracking();

  let products = collaborator?.products
  if (restriction?.restrict_products) {
    products = restriction.products
  }

  useOutsideClosePopup(wrapperRef, setOpenProduct);

  if (!logged && !products?.length > 0) {
    return null
  }

  const handleTracking = (title) => {
    sendTracking({
      action: 160,
      collaborator_id: collaborator.collaborator_id,
      componant: "Products",
      title: title,
    });
  };

  return (
    <>
      {
        restriction?.show_products && (
          <section className="mt-9">
            <div className="flex justify-between items-center mb-[3vh]">
              <h2 className="text-xl font-SteradianMedium">{translation?.sectionTitle?.products?.[lang]}</h2>
              {logged && (
                <EditButton
                  setOpenEditPopup={setOpenEditPopup}
                  initialEditId={initialEditId}
                  setComponantToEditId={setComponantToEditId}
                />
              )}
            </div>
            {logged &&
              !restriction?.restrict_products &&
              collaborator.products?.length === 0 && (
                <EmptyContent
                  setOpenEditPopup={setOpenEditPopup}
                  initialEditId={initialEditId}
                  setComponantToEditId={setComponantToEditId}
                  sectionTitle={translation?.sectionTitle?.products?.[lang]}
                />
              )}
            <Swiper
              slidesPerView={2}
              spaceBetween={100}
              cssMode={true}
              pagination={{
                enabled: true, clickable: true
              }}
              mousewheel={true}
              keyboard={true}
              modules={[Pagination, Mousewheel, Keyboard]}
              className="mt-3"
            >
              {products?.map((product, index) => (
                <SwiperSlide className="flex-col my-3" onClick={() => {
                  setOpenProduct(true);
                  setCurrentProduct(index);
                }}>
                  <div
                    className="relative h-44 w-44 rounded-2xl shadow-md"
                    onClick={() => { handleTracking(product?.title?.[lang]) }}
                  >
                    <img
                      className="object-cover h-44 w-44 rounded-2xl"
                      src={`${process.env.REACT_APP_IMAGE_PATH}${product?.pictures?.[0]}`}
                    />
                    {(product.price.amount !== '' && product.price.amount > 0) &&
                      (
                        <>
                          <div className="absolute bottom-0 bg-gradient-to-t from-[rgba(0,0,0,.6)] z-10 w-44 h-12 rounded-b-2xl" />
                          <div className="absolute text-white bottom-2 left-2 text-lg font-normal z-20">
                            {product?.price?.amount}{" "}
                            <span className="text-base">{product?.price?.currency}</span>
                          </div>
                        </>

                      )

                    }

                  </div>
                  <p className="mt-2 absolute bottom-[-20px] w-44">{product?.title?.[lang]?.length > 12 ? product?.title?.[lang].slice(0, 12) + "..." : product?.title?.[lang]}</p>
                </SwiperSlide>
              ))}
            </Swiper>
            {logged && openEditPopup && (
              <EditComponants
                componantId={componantToEditId}
                setOpenEditPopup={setOpenEditPopup}
              />
            )}
          </section>
        )}

      {
        openProduct && (
          <div className="flex items-center justify-center fixed top-0 w-full h-[100vh] bg-black bg-opacity-40 z-[9999999] ml-[-20px] mr-5 max-w-[580px]">
            <div ref={wrapperRef} className="flex flex-col bg-white rounded-lg w-[90%]">
              <div className="relative h-[235.51px]">
                <Swiper
                  modules={[Pagination, Navigation]}
                  slidesPerView={
                    products?.[currentProduct].pictures.length > 1 ? 1 : products?.[currentProduct].pictures.length === 1 ? 1 : 1
                  }
                  keyboard={true}
                  mousewheel={true}
                  pagination={{ enabled: true, clickable: true }}
                  navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  }}
                  className="
                swiper-carousel top-0 mt-0 max-w-[580px]
              "
                  id="swiperPrevfather"
                >
                  {products?.[currentProduct].pictures.map((item, index) => (
                    <SwiperSlide key={index} id="swiperPrevImae">
                      <div className="w-[100%] h-[235.51px] max-w-[580px]   top-0 left-0 z-[999] flex justify-center items-center relative">
                        <img
                          src={`${process.env.REACT_APP_IMAGE_PATH}` + item}
                          alt="imagee"
                          id="productImagePrev"
                          className="w-[100%] h-[100%]  rounded-t-lg object-cover "
                        />

                        <div className="back-carousel-containerr"></div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                {/* <img className="h-full w-full object-cover rounded-t-lg" src={`${process.env.REACT_APP_IMAGE_PATH}${products?.[currentProduct].pictures[0]}`} /> */}
                {
                  products?.[currentProduct]?.price?.amount > 0 && (
                    <div className="absolute top-48 right-5 text-[20px] text-white z-10">
                      {products?.[currentProduct]?.price?.amount}
                      <span className="text-[16px]"> {products?.[currentProduct]?.price?.currency}</span>
                    </div>
                  )
                }
                <div className="absolute left-0 bottom-0 bg-gradient-to-t from-[rgba(0,0,0,.6)] from-1% to-transparent to-99% bg-opacity-50 h-28 w-full" />

              </div>
              <div className="mx-[5%]">
                <div className="w-full flex flex-col gap-3 my-5">
                  <p className="text-[#000000] font-[500] text-[18px] w-[100%] mt-[10px] break-words">
                    {products?.[currentProduct].title?.[lang]}
                  </p>
                  {
                    products?.[currentProduct]?.links.length > 0 && (
                      <p className="text-[18px] font-[400] text-[#4E3131]">{translation?.productModal?.orderOn?.[lang]}</p>
                    )
                  }
                  <div className="flex gap-2">
                    {
                      products?.[currentProduct]?.links.map((link) => (
                        // <a href={link?.url && link?.url.startsWith("http")
                        //   ? link?.url
                        //   : `http://${link?.url}`} target="_blank">
                        //   <img src={`${link?.store?.icon}.png`} className="max-w-[65px] h-[65px] shadow-lg object-contain p-4 rounded-lg" />
                        // </a>
                        <a href={link.store.label === 'Whatsapp'
                        ? `https://wa.me/+${link.url.replace("-", "")}`
                        : (link.url && link.url.startsWith('http') ? link.url : `http://${link?.url}`)} target="_blank">
                          <img src={`${link?.store?.icon}.png`} className="max-w-[65px] h-[65px] shadow-lg object-contain p-4 rounded-lg" />
                        </a>
                        ))
                    }
                  </div>
                </div>

              </div>
            </div>
          </div>
        )
      }
    </>

  );
};

export default ShowProducts;
