import React, { useEffect, useRef, useState } from 'react'
import useOutsideClosePopup from '../../hooks/useOutsideClosePopup';
import { useDispatch, useSelector } from 'react-redux';
import { setLang } from '../../redux/appSlices/collaborator/collaboratorSlice';
import useLoggedStatus from '../../hooks/useLoggedStatus';
import { usePatchCollaboratorMutation } from '../../redux/appSlices/collaborator/collaboratorApiSlice';

const LanguageSelect = () => {

  const dispatch = useDispatch()

  const options = [
    { value: "fr", label: "FR", icon: "/assets/img/icons/fr-flag.svg" },
    { value: "en", label: "EN", icon: "/assets/img/icons/en-flag.svg" },
  ];




  // let lang = localStorage.getItem('lang') ?? useSelector((state) => state.collaborator.collaboratorInfo.language) ?? 'fr'
  const collabLang = useSelector((state) => state.collaborator.collaboratorInfo.language)
  const localLang = localStorage.getItem('lang')
  let lang = localLang ? localLang : collabLang
  const logged = useLoggedStatus();
  const collabId = useSelector((state) => state.collaborator.collaboratorInfo._id)

  const [selectedValue, setSelectedValue] = useState(lang === 'en' ? 1 : 0)
  const [optionValue, setOptionValue] = useState(selectedValue === 1 ? 0 : 1)
  const [openOption, setOpenOption] = useState(false)


  const wrapperRef = useRef(null);
  useOutsideClosePopup(wrapperRef, setOpenOption);

  const [patchCollaborator] = usePatchCollaboratorMutation()

  useEffect(() => {
    if (localLang) {
      lang = localLang;
      dispatch(setLang(localLang));
    } else if (collabLang) {
      lang = collabLang;
    }

    if (lang === 'fr') {
      setSelectedValue(0);
    } else {
      setSelectedValue(1);
    }
  }, [collabLang]);

  useEffect(() => {
    if (selectedValue == 0) {
      setOptionValue(1)
      dispatch(setLang('fr'))
      localStorage.setItem('lang', 'fr')

    } else if (selectedValue == 1) {
      setOptionValue(0)
      dispatch(setLang('en'))
      localStorage.setItem('lang', 'en')
    }

    if (logged && collabLang !== localStorage.getItem('lang')) {
      patchCollaborator({
        id: collabId,
        content: {
          language: options?.[selectedValue].value
        }
      })

    }
  }, [selectedValue])

  return (
    <div ref={wrapperRef} className='ml-auto'>
      <button
        className='bg-white bg-opacity-50 text-base flex justify-around ml-auto border border-white rounded-[0.25rem] items-center w-16 h-8'
        onClick={() => { setOpenOption(!openOption) }}
      >
        <img src={options?.[selectedValue].icon} />
        <span>{options?.[selectedValue].label}</span>
        <img className={openOption ? 'rotate-180' : ''} src='/assets/img/icons/down-filled.svg' />

      </button>
      {
        openOption &&
        <button
          className='bg-white bg-opacity-50 text-base flex justify-around ml-auto border border-white rounded-[0.25rem] items-center w-16 h-8 mt-1'
          onClick={() => { setSelectedValue(optionValue); setOpenOption(false) }}
        >
          <img src={options?.[optionValue].icon} />
          <span>{options?.[optionValue].label}</span>
        </button>
      }
    </div>
  )
}

export default LanguageSelect