import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";
import { useResendConfirmationMailMutation } from "../../redux/appSlices/onboarding/onboardingApiSlice";

const ConfirmEmail = () => {
  const confirmEmailTranslation = useSelector(
    (state) => state.translation.ConfirmEmail
  );
  const collaborator = useSelector(
    (state) => state.collaborator.collaboratorInfo
  );
  const lang = useSelector(
    (state) => state.collaborator.collaboratorInfo.language
  );
  const userEmail = useSelector(
    (state) => state.collaborator.collaboratorInfo.email
  );

  const [resendConfirmationMail] = useResendConfirmationMailMutation();

  const [resend, setResend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(
    confirmEmailTranslation.subtitle?.[lang]
  );

  useEffect(() => {
    if (resend) {
      setTimeout(() => {
        setResend(false);
      }, 5000);
    }
  }, [resend]);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_API_SOCKET_URL);
    socket.on("connect", () => {
      // console.log("socket connected");
      socket.emit("init", { collaborator_id: collaborator.collaborator_id });
      socket.on("reloadPage", () => {
        // console.log('shouldReload()')
        window.location.reload()
      });
    });
    return () => {
      socket?.disconnect();
    };
  }, []);

  const SendConfirmationEmail = () => {
    resendConfirmationMail(collaborator.collaborator_id)
      .unwrap()
      .then(() => {
        setResend(true);
        setMessage(confirmEmailTranslation.subtitleSecond?.[lang]);
      })
      .catch((err) => {
        setError(err?.data?.message?.[lang] ?? 'error');
      });
  };

  return (
    <section className="pt-[7vh] mx-5 pb-[120px]">
      <div className="font-SteradianMedium text-2xl">
        {confirmEmailTranslation.title?.[lang]}
      </div>
      {!error ? (
        <div className="text-[#198754] mt-24 text-lg">
          ✅ {message} {userEmail}.
        </div>
      ) : (
        <div className="text-red-500 mt-24 text-lg">🚫 {error}</div>
      )}
      <div className="mt-5 text-lg">
        {confirmEmailTranslation.subtitle2?.[lang]}
      </div>
      <div>
        <button
          className={resend ? "btn-fields" : "btn-active btn-fields"}
          disabled={resend}
          id="btn-next"
          style={{
            display: "flex",
            gap: "4px",
            justifyContent: "center",
            alignItems: "center",
            bottom: "92px",
          }}
          onClick={SendConfirmationEmail}
        >
          <img
            src="/assets/img/loaders/loading.gif"
            style={{ display: loading ? undefined : "none" }}
            width="23px"
          />
          {confirmEmailTranslation.button?.[lang]}
        </button>
      </div>
    </section>
  );
};

export default ConfirmEmail;
