import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { v4 as uuidv4 } from "uuid";
import { setOnboardingStep } from "../../redux/appSlices/collaborator/collaboratorSlice";
import ShowVideo from "../UI/ShowVideo";
import { useNavigate } from "react-router-dom";
import { usePatchCollaboratorMutation } from "../../redux/appSlices/collaborator/collaboratorApiSlice";
import LottieChangeLang from "../animation/LottieChangeLang";
import useCollaboratorRestriction from "../../hooks/useCollaboratorRestriction";

const Videos = ({ edit, finalStep, setOpenEditPopup }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const videosTranslation = useSelector((state) => state.translation.Videos);
  const lang = useSelector(
    (state) => state.collaborator.collaboratorInfo.language
  );

  const collaborator = useSelector(
    (state) => state.collaborator.collaboratorInfo
  );

  const restriction = useCollaboratorRestriction();

  const [patchCollaborator, { isLoading }] = usePatchCollaboratorMutation();

  const [listVideos, setListVideos] = useState(collaborator.videos);

  useEffect(() => {
    if(restriction.restrict_videos) {
      setListVideos(restriction.videos)
    }
  }, [restriction])

  // const [finalStep, setFinalStep] = useState(false);

  // ############### DEV AREA
  // ######################
  // ########################################################

  const [safe, setSafe] = useState(true);
  const [loading, setLoading] = useState();

  const handleAdd = () => {
    setListVideos([
      ...listVideos,
      {
        _id: uuidv4(),
        description: "",
        type: 1,
        url: "",
      },
    ]);
  };

  const handleChange = (e, index, value) => {
    const newlist = [...listVideos];
    // e.target.name === "description"
    //   ? (newlist[index] = {
    //       ...newlist[index],
    //       [e.target.name]: { [lang]: e.target.value },
    //     })
    //   : (newlist[index] = {
    //       ...newlist[index],
    //       [e.target.name]: e.target.value,
    //     });

    if (e.target.name == "description") {
      newlist[index] = {
        ...newlist[index],
        [e.target.name]: { [lang]: e.target.value },
      };
    } else if (value) {
      newlist[index] = {
        ...newlist[index],
        type: `${value}`,
      };
    } else {
      newlist[index] = {
        ...newlist[index],
        [e.target.name]: e.target.value,
      };
    }

    setListVideos(newlist);
  };

  const handleDelete = (_id) => {
    const newList = listVideos.filter((item, i) => item._id !== _id);
    setListVideos(newList);
  };

  const handleSubmit = () => {
    try {
      patchCollaborator({
        id: collaborator._id,
        content: {
          ...(edit ? {} : { onboarding_step: 10 }),
          videos: [...listVideos],
        },
      }).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  const handleFinishClick = async () => {
    // dispatch(setOnboardingStep({ onboarding_step: 10 }));
    navigate(`/${collaborator.collaborator_id}`);
    // window.location = `/${collaborator.collaborator_id}`;
  };

  // ########################################################
  // ######################
  // ############### END DEV AREA

  return (
    <>
      {finalStep ? (
        <section className="pt-[7vh] mx-5">
          <div className="flex flex-col justify-center items-center">
            <div>
              <img
                src="/assets/img/logo-black.svg"
                className="w-[139px] object-contain"
              />
            </div>
            <div className="text-xl max-w-[239px] mt-12 text-center">
              {videosTranslation.title2[lang]}
            </div>
          </div>
          <div className="h-[300px]">
            <LottieChangeLang />
          </div>

          <div className="button-container">
            <button className="btn-welcome" onClick={handleFinishClick}>
              {videosTranslation.buttonFinish?.[lang]}
            </button>
          </div>
        </section>
      ) : (
        <section className="pt-[7vh] mx-5 pb-[120px]">
          <div>
            <div className="text-2xl font-SteradianMedium">
              {videosTranslation.title[lang]}
            </div>
            <div className="text-lg mt-5">
              {videosTranslation.subtitle[lang]}
            </div>
            {/* {!restriction.restrict_videos && ( */}
            {/* // <> */}
            <div className="mt-7">
              {listVideos.map((video, i) =>
                restriction.restrict_videos ? (
                  <div>
                    <div className="flex items-center justify-between w-[122px] h-[44px] bg-labelsBg border border-labels rounded-lg py-2 px-5 mt-[20px]">
                      {video?.type === '1' ? 'Youtube' : "Vimeo"}
                      <img src="../../assets/img/icons/lock.svg" className="w-[15px] h-[15px]" />
                    </div>

                    <p className="my-[10px]">{video?.url}</p>
                    <p className="my-[10px]">{video?.description?.[lang]}</p>

                  </div>
                ) : (
                  <ShowVideo
                    key={video._id}
                    data={video}
                    index={i}
                    lang={lang}
                    handleChange={handleChange}
                    handleDelete={handleDelete}
                  />
                )
              )}
            </div>
            {!restriction.restrict_videos && (
              <div
                onClick={handleAdd}
                className="flex mt-5 text-labels items-center gap-3 font-SteradianMedium cursor-pointer"
              >
                <AiOutlinePlusCircle />
                {videosTranslation.button[lang]}
              </div>
            )}
          </div>
          <div className="button-container">
            <button
              className={safe ? "btn-fields btn-active" : "btn-fields"}
              id="btn-next"
              onClick={
                edit
                  ? (e) => {
                      handleSubmit(e);
                      setOpenEditPopup(false);
                    }
                  : handleSubmit
              }
              disabled={!safe}
            >
              {edit ? (
                <img
                  src="/assets/img/loaders/loading.gif"
                  style={{
                    display: loading ? undefined : "none",
                    padding: "2px",
                  }}
                  width="23px"
                  alt=""
                  className="ml-[45%]"
                />
              ) : null}
              {loading
                ? null
                : edit
                ? videosTranslation?.buttonSave[lang]
                : videosTranslation?.buttonNext[lang]}
            </button>
          </div>
        </section>
      )}
    </>
  );
};

export default Videos;
