import React, { useEffect, useState } from "react";
// import { useGlobalState } from "..";
// import AppServices from "../services/AppServices";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import { translation } from "../../../translation";
import { useSelector } from "react-redux";
import { useChangeCollabPassMutation } from "../../../redux/appSlices/collaborator/collaboratorApiSlice";

const SettingPass = ({ setStep2, setStep1, setOpenEditPopup }) => {

  const lang = useSelector(
    (state) => state.collaborator.collaboratorInfo.language
  );

  const collaborator = useSelector((state) => state.collaborator.collaboratorInfo);
  // const [trans] = useGlobalState("onboarding_trans");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmError, setConfirmError] = useState(false);
  const [currentError, setCurrentError] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordVisibleC, setPasswordVisibleC] = useState(false);
  const [safePass, setSafePass] = useState(false);

  const [changeCollabPass] = useChangeCollabPassMutation()

  useEffect(() => {
    if (
      currentPassword?.length >= 6 &&
      newPassword?.length >= 6 &&
      confirmPassword?.length >= 6
    ) {
      setSafePass(true);
    } else {
      setSafePass(false);
    }
  }, [currentPassword, newPassword, confirmPassword]);

  useEffect(() => {
    if (newPassword !== confirmPassword) {
      setConfirmError(true);
    } else {
      setConfirmError(false);
    }
  }, [confirmPassword]);

  function handleSubmitPass(event) {
    event.preventDefault();
    // check if the confirm password matches the new password
    if (newPassword !== confirmPassword) {
      setConfirmError(true);
    } else {
      // do something with the passwords
      // AppServices.post(
      //   {
      //     action: 2,
      //     data: {
      //       password: currentPassword,
      //       new_password: newPassword,
      //     },
      //   },
      //   "/app"
      // )
      //   .then((response) => {
      //     // reset the form
      //     setCurrentPassword("");
      //     setNewPassword("");
      //     setConfirmPassword("");
      //     setConfirmError(false);
      //     setCurrentError(false);
      //     seteditComponantId(-1);
      //     // set steps false
      //     setStep1(false);
      //     setStep2(false);
      //   })
      //   .catch((error) => {
      //     setCurrentError(true);
      //   });

      changeCollabPass({
        oldPassword: currentPassword,
        password: newPassword,
        passwordConfirm: newPassword,
        collaborator_id: collaborator.collaborator_id
      }).unwrap().then((response) => {
        // reset the form
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setConfirmError(false);
        setCurrentError(false);
        // seteditComponantId(-1);          SHOULD CHANGE THIS TO CLOSE THE POPUP
        // set steps false
        setStep1(false);
        setStep2(false);
        setOpenEditPopup(false)
      }).catch((error) => {
          setCurrentError(true);
        });
    }
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const togglePasswordVisibilityC = () => {
    setPasswordVisibleC(!passwordVisibleC);
  };

  return (
    <section className="mx-5 pt-[7vh] fields-content overflow-hidden">
      <img
        src="../../assets/img/icons/arrow-back.svg"
        alt=""
        srcSet=""
        onClick={() => {
          setStep2(false);
        }}
        className="cursor-pointer absolute top-0 mt-[40px] ml-[-10px]"
      />
      <div className="field-title text-2xl font-SteradianMedium">
        {translation.settings["password-change-section"].title[lang]}{" "}
      </div>
      <div className="mt-[1rem] text-[17px] font-[500]">
        {translation.settings["password-change-section"].subtitle[lang]}
      </div>

      {currentError && (
        <div className="text-[14px] text-[#F25252] mt-[0.5rem]">
          {translation.settings["password-change-section"].incorrect[lang]}
        </div>
      )}
      {confirmError && (
        <div className="text-[14px] text-[#F25252] mt-[0.5rem]">
          {translation.settings["password-change-section"].different[lang]}
        </div>
      )}
      <form
        onSubmit={handleSubmitPass}
        className="form-pass flex flex-col items-center gap-4"
      >
        <div className="password-input-container">
          <label
            htmlFor="currentPassword"
            className={`
                    ${currentError ? "text-red-500" : "text-black"}
                  `}
          >
            {translation.settings["password-change-section"].actuel[lang]}
          </label>
          <input
            type={passwordVisible ? "text" : "password"}
            id="currentPassword"
            value={currentPassword}
            className={currentError ? "input-error" : "input__field bg-transparent"}
            onChange={(event) => setCurrentPassword(event.target.value)}
          />
          <div className="password-toggle" onClick={togglePasswordVisibility}>
            {passwordVisible ? (
              <RiEyeOffLine className="text-[#795656]" />
            ) : (
              <RiEyeLine className="text-[#795656]" />
            )}
          </div>
        </div>
        <div className="password-input-container">
          <label htmlFor="newPassword">
            {translation.settings["password-change-section"].nouveau[lang]}
          </label>
          <input
            type={passwordVisibleC ? "text" : "password"}
            id="newPassword"
            value={newPassword}
            className={confirmError ? "input-error" : "input__field bg-transparent"}
            onChange={(event) => setNewPassword(event.target.value)}
          />
          <div className="password-toggle" onClick={togglePasswordVisibilityC}>
            {passwordVisibleC ? (
              <RiEyeOffLine className="text-[#795656]" />
            ) : (
              <RiEyeLine className="text-[#795656]" />
            )}
          </div>
        </div>
        <div className="password-input-container">
          <label
            htmlFor="confirmPassword"
            className={`
                    ${confirmError ? "text-red-500" : "text-black"}
                  `}
          >
            {translation.settings["password-change-section"].confirmer[lang]}
          </label>
          <input
            type={passwordVisibleC ? "text" : "password"}
            id="confirmPassword"
            value={confirmPassword}
            onChange={(event) => setConfirmPassword(event.target.value)}
            className={confirmError ? "input-error" : "input__field bg-transparent"}
          />
          <div className="password-toggle" onClick={togglePasswordVisibilityC}>
            {passwordVisibleC ? (
              <RiEyeOffLine className="text-[#795656]" />
            ) : (
              <RiEyeLine className="text-[#795656]" />
            )}
          </div>
        </div>
        <div className="button-container">
          <button
            className={safePass ? "btn-fields btn-active" : "btn-fields"}
            id="btn-next"
            // onClick={handleSubmit}
            disabled={!safePass}
          >
            {translation.settings["password-change-section"]?.button?.[lang]}
          </button>
        </div>
      </form>
    </section>
  );
};

export default SettingPass;
