import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  useGetStoresQuery,
  usePostUploadPicMutation,
} from "../../redux/appSlices/generalApiSlice";
import { translation } from "../../translation";
import { useDeleteCollaboratorImageMutation } from "../../redux/appSlices/collaborator/collaboratorApiSlice";

function AddProductModal({
  close,
  modalTranslation,
  lang,
  listProduct = {},
  setListProduct,
  productIdToEdit,
  setProductIdToEdit,
}) {
  const [safe, setSafe] = useState(false);
  const [currency, setCurrency] = useState("MAD");
  const [openCurrency, setOpenCurrency] = useState(false);
  const [currentStoreToOpen, setCurrentStoreToOpen] = useState();
  const [openStoreList, setOpenStoreList] = useState(false);
  const [storeList, setStoreList] = useState([]);
  const [modalData, setModalData] = useState({
    title: {},
    price: {
      amount: 0,
      currency: "MAD",
    },
    pictures: [],
    links: [],
    _id: uuidv4(),
  });

  const [postUploadPic] = usePostUploadPicMutation();
  const [deletePic] = useDeleteCollaboratorImageMutation();

  const { data } = useGetStoresQuery();

  useEffect(() => {
    if (modalData?.title?.[lang]?.length > 0) {
      setSafe(true);
    } else {
      setSafe(false);
    }
  }, [modalData]);

  useEffect(() => {
    const oldPrice = modalData.price;
    setModalData({
      ...modalData,
      price: { ...oldPrice, currency: currency },
    });
  }, [currency]);

  useEffect(() => {
    if (productIdToEdit !== null) {
      const productEditData = listProduct.find(
        (product) => product._id === productIdToEdit
      );
      setModalData({ ...productEditData });
    }
  }, [productIdToEdit]);

  useEffect(() => {
    if (data) {
      setStoreList(data.data.stores);
    }
  }, [data]);

  const handleModalChange = (e) => {
    if (e.target.name === "title") {
      setModalData({
        ...modalData,
        [e.target.name]: { [lang]: e.target.value },
      });
    } else if (e.target.name === "amount") {
      const oldPrice = modalData.price;
      setModalData({
        ...modalData,
        price: { ...oldPrice, [e.target.name]: e.target.value },
      });
    }
    // else if (e.target.name === "url") {
    //   const OldLinks = modalData.links;

    //   setModalData({
    //     ...modalData,
    //     links: { [e.target.name]: e.target.value },
    //   });
    // }
    else {
      setModalData({
        ...modalData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const hangleAddImage = (e) => {
    postUploadPic(e.target.files[0])
      .unwrap()
      .then((res) => {
        let newPictures = modalData.pictures;
        setModalData({
          ...modalData,
          pictures: [...newPictures, res.data.file],
        });

        // newPictures.push(res.data.file);
        // console.log("res.data", res.data.file);
        // setModalData({
        //   ...modalData,
        // });
      });
  };

  const handleDeleteImage = (fileName) => {
    const newImages = modalData.pictures.filter(
      (picture) => picture !== fileName
    );
    deletePic(fileName).unwrap();
    setModalData({
      ...modalData,
      pictures: newImages,
    });
  };

  const handleAddStore = (e) => {
    setModalData({
      ...modalData,
      links: [
        ...modalData.links,
        {
          store: {
            _id: "6405b55177dbb3e4441f7985",
            label: "Glovo",
            icon: "/assets/logo/glovo",
            __v: 0,
          },
          url: "",
          _id: uuidv4(),
        },
      ],
    });
  };

  const updateStoreLink = (e, id) => {
    const linkIndex = modalData.links.findIndex((item) => item._id === id);
    if (linkIndex !== -1) {
      const updatedLinks = [...modalData.links];
      // updatedLinks[linkIndex] = {
      //   ...updatedLinks[linkIndex],
      //   url: e.target.value,
      // };

      if (e.target.name === "prefix" || e.target.name === "phone") {
        const existingUrlParts = updatedLinks[linkIndex].url.split("-");
        const prefix =
          e.target.name === "prefix" ? e.target.value : existingUrlParts[0];
        const phone =
          e.target.name === "phone" ? e.target.value : existingUrlParts[1];

        updatedLinks[linkIndex] = {
          ...updatedLinks[linkIndex],
          url: `${prefix || ""}-${phone || ""}`,
        };
      } else {
        updatedLinks[linkIndex] = {
          ...updatedLinks[linkIndex],
          url: e.target.value,
        };
      }
      setModalData((prevState) => ({
        ...prevState,
        links: updatedLinks,
      }));
    }
  };

  const handleStoreSelection = (selectedStoreId, id) => {
    const linkIndex = modalData.links.findIndex((item) => item._id === id);
    const selectedStore = storeList.find(
      (item) => item._id === selectedStoreId
    );
    const updatedLinks = [...modalData.links];
    updatedLinks[linkIndex] = {
      ...updatedLinks[linkIndex],
      store: selectedStore,
    };

    setModalData((prevState) => ({
      ...prevState,
      links: updatedLinks,
    }));

    setOpenStoreList(false);
  };

  const handleStoreDelete = (id) => {
    const newLinks = modalData.links.filter((item) => item._id !== id);
    setModalData({
      ...modalData,
      links: newLinks,
    });
  };

  return (
    <div className="fixed inset-0 bg-[#00000090] z-[99999999] flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-md w-[90%] max-h-[550px] relative overflow-auto flex flex-col gap-3">
        <div
          onClick={() => {
            close(false);
            setProductIdToEdit(null);
          }}
          className="cursor-pointer absolute right-4"
        >
          <img
            className="w-4 h-4"
            src="/assets/img/icons/close.svg"
            alt="close Popup"
          />
        </div>
        <div></div>
        <div className="text-[20px] text-labels font-medium">
          {/* // TODO: change the text id edit or not */}
          {modalTranslation.titleAdd[lang]}
        </div>
        {/* ######## PRODUCT title ######## */}
        <div>
          <label className="text-[14px] font-medium text-black">
            {modalTranslation.name[lang]}
          </label>
          <input
            type="text"
            name="title"
            value={modalData?.title?.[lang]}
            className="block w-full border border-inputBottom rounded-lg p-2 mt-2 focus:outline-none"
            onChange={handleModalChange}
          />
        </div>
        {/* ######## END PRODUCT title ######## */}

        {/* ######## price ######## */}
        <div>
          <label className="text-[14px] font-medium text-black">
            {modalTranslation.prix[lang]}
          </label>
          <div className="flex gap-5 items-center mt-2">
            <input
              name="amount"
              placeholder="0"
              value={modalData?.price?.amount || ""}
              className="border border-inputBottom rounded-lg p-2 focus:outline-none text-black w-full max-w-[192px]"
              onChange={handleModalChange}
            />
            <div>
              <div
                className=" relative flex items-center justify-between bg-labelsBg border border-inputBottom rounded-lg px-[9px] h-11 w-[122px] cursor-pointer"
                onClick={() => setOpenCurrency(!openCurrency)}
              >
                {currency}{" "}
                <img
                  src="../../assets/img/icons/arrow-down.svg"
                  className={openCurrency && "rotate-180"}
                  alt=""
                />
              </div>
              {openCurrency && (
                <div className="absolute bg-[#fff3e7] p-[13px] flex flex-col gap-[10px] border border-[#ccb7b7] rounded-lg mt-[10px] w-[122px] z-10">
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setOpenCurrency(false);
                      setCurrency("MAD");
                    }}
                  >
                    MAD
                  </div>
                  <div className="w-full h-[1px] bg-[#ccb7b7]" />
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setOpenCurrency(false);
                      setCurrency("EUR");
                    }}
                  >
                    EUR
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* ######## END price ######## */}

        {/* ######## Images ######## */}
        <div>
          <div className="text-[14px] font-medium text-black mt-[30px] mb-2">
            {modalTranslation.image[lang]}
          </div>
          <div className="flex justify-around">
            {/* MAp the Images */}
            {modalData?.pictures?.map((picture) => (
              <div
                key={picture}
                className="relative w-[90px] h-[90px] rounded-[10px]"
              >
                <img
                  className="w-full h-full object-cover rounded-[10px]"
                  src={`${process.env.REACT_APP_IMAGE_PATH}${picture}`}
                />
                <div
                  className="absolute w-full bottom-0 bg-[#4e3131] rounded-b-[10px] text-[#fff] text-center text-[15px] py-[7px] cursor-pointer"
                  onClick={() => handleDeleteImage(picture)}
                >
                  {translation?.loginPage?.delete?.[lang]}
                </div>
              </div>
            ))}

            {modalData?.pictures?.length < 3 && (
              <>
                <label
                  className={`bg-labelsBg border border-dashed border-inputBottom rounded-[9px] flex flex-col items-center justify-center gap-3 py-4 cursor-pointer ${
                    modalData?.pictures?.length === 0
                      ? "w-full !py-[30px]"
                      : "h-[90px]"
                  }`}
                  htmlFor="productImages"
                >
                  <span className="w-[100px] text-center text-labels">
                    {modalTranslation?.addImageButton?.[lang]}
                  </span>
                  <img
                    className="w-5 h-5"
                    src="../../assets/img/icons/plus-circle.svg"
                    alt="add"
                  />
                </label>
                <input
                  id="productImages"
                  className="hidden"
                  name="image"
                  type="file"
                  accept="image/*"
                  onChange={hangleAddImage}
                />
              </>
            )}
          </div>
        </div>
        {/* ######## END Images ######## */}

        {/* ######## Sales link ######## */}
        <div>
          <div className="text-[14px] font-medium text-black mt-[20px] mb-2">
            {modalTranslation.link[lang]}
          </div>

          {modalData?.links?.map((store, index) => (
            <>
              <div
                className="flex justify-between items-center mt-2"
                key={store._id}
              >
                <div>
                  <button
                    className="relative flex items-center justify-between bg-labelsBg border border-inputBottom rounded-lg px-[9px] h-11 w-[122px]"
                    onClick={() => {
                      setOpenStoreList(!openStoreList);
                      setCurrentStoreToOpen(store._id);
                    }}
                  >
                    {store?.store.label}
                    <img src="../../assets/img/icons/arrow-down.svg" alt="" />
                  </button>

                  {openStoreList &&
                    currentStoreToOpen === modalData?.links[index]?._id && (
                      <div className="flex flex-col gap-[10px] w-[230px] h-[265px] absolute overflow-scroll p-[13px] bg-[#fff3e7] border border-inputBottom rounded-[9px] z-30">
                        {storeList?.map((store) => (
                          <>
                            <div
                              key={store._id}
                              className="flex gap-[21px] items-center cursor-pointer"
                              onClick={() => {
                                handleStoreSelection(
                                  store._id,
                                  modalData.links[index]._id
                                );
                              }}
                            >
                              <img
                                src={`${store?.icon}.png`}
                                alt={store?.label}
                                className="w-[17px] h-[17px]"
                              />
                              {store?.label}
                            </div>
                            <div className="w-full !h-[1px] bg-black z-10" />
                          </>
                        ))}
                      </div>
                    )}
                </div>

                <div onClick={() => handleStoreDelete(store._id)}>
                  <img
                    src="../../assets/img/icons/delete.svg"
                    alt="Delete sales link"
                  />
                </div>
              </div>

              {!(store.store.label === "Whatsapp") ? (
                <input
                  value={store?.url}
                  name="url"
                  className="block w-full border-b border-inputBottom p-2 mt-2 mb-4 focus:outline-none"
                  placeholder="www"
                  onChange={(e) => updateStoreLink(e, store._id)}
                />
              ) : (
                <div className="flex gap-5 mt-2 mb-4">
                  <div className="border-b border-inputBottom">
                    <span>+</span>
                    <input
                      type="number"
                      className="w-16 bg-transparent text-lg focus:outline-none"
                      name="prefix"
                      defaultValue={store.url.split("-")[0] ?? ""}
                      onChange={(e) => updateStoreLink(e, store._id)}
                    />
                  </div>
                  <input
                    type="tel"
                    className="border-b border-inputBottom bg-transparent text-lg focus:outline-none"
                    name="phone"
                    placeholder="60000000"
                    defaultValue={store.url.split("-")[1] ?? ""}
                    onChange={(e) => updateStoreLink(e, store._id)}
                  />
                </div>
              )}
            </>
          ))}
        </div>
        {/* ######## END Sales link ######## */}

        <div
          className="flex items-center mt-[20px] gap-2 text-addNew font-SteradianMedium text-[17px] cursor-pointer"
          onClick={handleAddStore}
        >
          <img
            className="w-5 h-5"
            src="../../assets/img/icons/plus-circle.svg"
            alt="add slaes link"
          />
          {modalTranslation.button[lang]}
        </div>

        <div className="flex items-center justify-center mt-10">
          <button
            onClick={() => {
              if (productIdToEdit) {
                const productIndex = listProduct.findIndex(
                  (item) => item._id === productIdToEdit
                );

                if (productIndex !== -1) {
                  const newProducts = [...listProduct];
                  newProducts[productIndex] = modalData;
                  setListProduct(newProducts);
                  close(false);
                  setProductIdToEdit(null);
                }
              } else {
                setListProduct([...listProduct, modalData]);
                close(false);
              }
            }}
            className={
              "rounded-[6px] w-40 h-11 border" +
              (safe
                ? " text-white bg-black border-black "
                : " bg-transparent text-inputBottom border-inputBottom")
            }
          >
            {modalTranslation.buttonSave[lang]}
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddProductModal;
