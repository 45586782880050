import React from 'react'

const EditButton = ({ setOpenEditPopup, initialEditId, setComponantToEditId }) => {
  return (
    <div className="flex gap-3">
      <div
        className="btn-edit cursor-pointer"
        onClick={() => {
          setOpenEditPopup(true);
          setComponantToEditId(initialEditId);
        }}
      >
        <img src="../../assets/img/icons/edit-icon.svg" alt="" />
      </div>
      <div
        className="btn-drag"
        onClick={() => {
          setOpenEditPopup(true);
          setComponantToEditId(0);
        }}
      >
        <img src="../../assets/img/icons/drag-icon.svg" alt="" />
      </div>
    </div>
  )
}

export default EditButton