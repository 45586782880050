import React from "react";
import LottieCard from "../../animation/LottieCard";

const StepTwo = ({ setAuthStep, authTranslation, lang, restriction }) => {
  return (
    <section className="fields-content step2 mx-5">
      <div className="field-title flex pt-[8vh] text-2xl font-SteradianMedium">
        {authTranslation.title2?.[lang]}
        <img
          src={`${
            // branding?.logo ? branding?.logo :
            "../../assets/img/logo-black.svg"
          }`}
          style={{ margin: "0 10px" }}
          alt=""
          srcSet=""
          className="w-[129px] object-contain"
        />
        👋🏻
      </div>
      <div className="field-description text-lg mt-4">

        {restriction.show_onboarding_name ? (
          <p>
            {`${authTranslation.subtitle2One?.[lang]}`}
            <p>
            {authTranslation?.offered?.[lang]}{" "}
            <span className="font-SteradianMedium">{restriction.onboarding_name}</span></p>
          </p>
        ) : (
          `${authTranslation.subtitle2One?.[lang]}.`
        ) }

        <span className="inline-block mt-9">
          {authTranslation.subtitle2Two?.[lang]}
        </span>
      </div>

      <LottieCard />

      <div className="button-container">
        <button
          className="btn-welcome"
          onClick={() => {
            setAuthStep(2);
          }}
        >
          {authTranslation.buttonTwo?.[lang]}
        </button>
      </div>
    </section>
  );
};

export default StepTwo;
