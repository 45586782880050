import React, { useState } from "react";
import { useSelector } from "react-redux";

import ReactPlayer from "react-player";

import EditButton from "../EditButton";
import useLoggedStatus from "../../../hooks/useLoggedStatus";
import EditComponants from "../EditComponants";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Mousewheel, Pagination, Keyboard } from "swiper/modules";
import useCollaboratorRestriction from "../../../hooks/useCollaboratorRestriction";
import { translation } from "../../../translation";
import EmptyContent from "../EmptyContent";
import useTracking from "../../../hooks/useTracking";

const ShowVideos = () => {
  const collaborator = useSelector(
    (state) => state.collaborator.collaboratorInfo
  );
  const lang = useSelector(
    (state) => state.collaborator.collaboratorInfo.language
  );

  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [componantToEditId, setComponantToEditId] = useState(0);
  const initialEditId = 8;

  const logged = useLoggedStatus();
  const restriction = useCollaboratorRestriction();
  const { sendTracking } = useTracking();

  let videos = collaborator?.videos;
  if (restriction?.restrict_videos) {
    videos = restriction?.videos;
  }

  if (!logged && !videos?.length > 0) {
    return null;
  }

  const handleTracking = (videoUrl, videoTitle ) => {
    sendTracking({
      action: 150,
      collaborator_id: collaborator.collaborator_id,
      componant: "Video",
      url: videoUrl,
      title: videoTitle,
    });
  };

  return (
    <>
      {restriction?.show_videos && (
        <section className="mt-9">
          <div className="flex justify-between items-center mb-[3vh]">
            <h2 className="text-xl font-SteradianMedium">
              {translation?.sectionTitle?.videos?.[lang]}
            </h2>
            {logged && (
              <EditButton
                setOpenEditPopup={setOpenEditPopup}
                initialEditId={initialEditId}
                setComponantToEditId={setComponantToEditId}
              />
            )}
          </div>
          {logged &&
            !restriction?.restrict_videos &&
            collaborator.videos?.length === 0 && (
              <EmptyContent
                setOpenEditPopup={setOpenEditPopup}
                initialEditId={initialEditId}
                setComponantToEditId={setComponantToEditId}
                sectionTitle={translation?.sectionTitle?.videos?.[lang]}
              />
            )}
          <div className="mt-3">
            <Swiper
              Keyboard={true}
              mousewheel={true}
              spaceBetween={10}
              cssMode={true}
              pagination={{
                enabled: true,
                type: "fraction",
              }}
              modules={[Mousewheel, Pagination, Keyboard]}
              className="videoSwiper"
            >
              {videos?.map((video) => (
                <div key={video._id}>
                  <SwiperSlide className="flex-col items-start" key={video._id}>
                    <ReactPlayer
                      url={
                        video.url.includes("youtu")
                          ? "https://www.youtube.com/embed/" +
                            (video.url.includes("youtu.be")
                              ? video.url.split("/")[3]
                              : video.url.split("v=")[1]?.split("&")[0])
                          : "https://player.vimeo.com/video/" +
                            video.url.split("/")[3]
                      }
                      width="100%"
                      height="281px"
                      controls={true}
                      onPlay={() => {
                        //   setCurrentVideo(i);
                        //   setVideoUrl(
                        //     videos.url.includes("youtu")
                        //       ? "https://www.youtube.com/embed/" +
                        //           (videos.url.includes("youtu.be")
                        //             ? videos.url.split("/")[3]
                        //             : videos.url.split("v=")[1]?.split("&")[0])
                        //       : "https://player.vimeo.com/video/" +
                        //           videos.url.split("/")[3]
                        //   );
                        //   setVideoTitle(videos.description?.[lang]);
                        //   handleSendTracking();
                        handleTracking(video?.url, video?.description?.[lang])
                      }}
                    />
                    {/* <iframe
                      src={
                        video.url.includes("youtu")
                          ? "https://www.youtube.com/embed/" +
                            (video.url.includes("youtu.be")
                              ? video.url.split("/")[3]
                              : video.url.split("v=")[1]?.split("&")[0])
                          : "https://player.vimeo.com/video/" +
                            video.url.split("/")[3]
                      }
                      className="w-full h-[281px]"
                      frameborder="0"
                      allowfullscreen
                    ></iframe> */}
                    <p className="py-[10px] text-[15px]">
                      {video.description?.[lang]}
                    </p>
                  </SwiperSlide>
                </div>
              ))}
            </Swiper>
          </div>
          {logged && openEditPopup && (
            <EditComponants
              componantId={componantToEditId}
              setOpenEditPopup={setOpenEditPopup}
            />
          )}
        </section>
      )}
    </>
  );
};

export default ShowVideos;
