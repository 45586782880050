import React, { useEffect, useState } from "react";
import useWindowScrollPosition from "../../hooks/useWindowScrollPosition";
import { useSelector } from "react-redux";
import { translation } from "../../translation";
import { usePostCreateIdentifMutation } from "../../redux/appSlices/collaborator/collaboratorApiSlice";
import IdentificationForm from "../Modal/IdentificationForm";
import ContactLock from "./ContactLock";
import useTracking from "../../hooks/useTracking";
import useCollaboratorRestriction from "../../hooks/useCollaboratorRestriction";

const AddToContactButtonFloat = ({ id, traking, user, dataProfile }) => {

  const lang = useSelector((state) => state.collaborator.collaboratorInfo.language) ?? 'fr';
  const collaborator = useSelector((state) => state.collaborator.collaboratorInfo);

  const { sendTracking } = useTracking();
  const scrollPosition = useWindowScrollPosition();
  const restriction = useCollaboratorRestriction();
  const [postCreateIdentif] = usePostCreateIdentifMutation()

  const [fetchGetVcard, setFetchGetVcard] = useState(false)
  const [openIdentification, setOpenIdentification] = useState(false)
  const [loading, setLoading] = useState(false);
  const [dataRes, setDataRes] = useState("");
  const [openFormContactLock, setOpenFormContactLock] = useState(false)
  const [contactAccess, setContactAccess] = useState(false)

  useEffect(() => {
    setContactAccess(!collaborator.contact_locked)
  }, [collaborator.contact_locked])

  // to show the loading spinner
  useEffect(() => {
    if (fetchGetVcard) {
      const timeoutId = setTimeout(() => {
        setFetchGetVcard(false);
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [fetchGetVcard]);





  const handleDownload = () => {
    if (contactAccess) {
      const csvDataUri = `${process.env.REACT_APP_API_BASE_URL}/vcards/${collaborator._id}`
      sendTracking({
        action: 65,
        collaborator_id: collaborator.collaborator_id,
        componant: "MainButton",
      })
      setTimeout(() => {
        window.open(csvDataUri, "_self")
      }, 2000);

    } else {
      setOpenFormContactLock(true)
    }
  }

  const handleIdentify = () => {
    postCreateIdentif({
      card: "",
      email: "",
      first_name: "",
      last_name: "",
      phone: "",
      identified_in: collaborator?._id,
      tracking: localStorage.getItem("tracking"),
      identification_methode: 0,
      user_token: localStorage.getItem("user_token"),
    })
      .unwrap()
      .then((res) => {
        localStorage.setItem("completerId", res.data.identification._id);
        setDataRes(res.data.identification);
        setOpenIdentification(true);
      })
      .catch((err) => {
        console.log(err);
        setDataRes(err.data);
        localStorage.setItem("completerId", err?.data?._id);
        setOpenIdentification(true);
      })
  }

  // Button Style

  const [button, setButton] = useState({
    fontSize: 15,
    visibility: "visible",
    transition:
      "visibility 0s ease 0s, opacity 0.2s linear 0s, color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s",
    width: "100%",
    marginRight: 21,
    background: collaborator?.branding?.color ? collaborator.branding.color : "black",
    color: "white",
    border: "none",
  });

  const shared_style = {
    fontSize: "15px",
    visibility: "visible",
    opacity: 1,
    transition:
      "visibility 0s, opacity 0.2s linear, color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
    width: "100%",
    // marginRight: "20px",
  };

  const button_styles = {
    style1: {
      ...shared_style,
      background: "white",
      color: "black",
      border: "none",
    },
    style2: {
      ...shared_style,
      background: collaborator?.branding?.color ? collaborator.branding.color : "black",
      // background: "black",
      color: "white",
    },
    style3: {
      ...shared_style,
      visibility: "hidden",
      opacity: 0,
    },
  };

  useEffect(() => {
    setTimeout(() => {
      try {
        var element_ = document.getElementById("show-btn");
        var a = element_.getBoundingClientRect();

        var footer_ = document.getElementById("footer-start");
        var f = footer_.getBoundingClientRect();

        if (a.y + 60 < window.scrollY) {
          if (f.y - f.height - 80 < window.scrollY) {
            setButton(button_styles.style1);
          } else {
            setButton(button_styles.style2);
          }
        } else {
          setButton(button_styles.style3);
        }
      } catch (error) {
        // console.log("error");
      }
    }, 0);
  }, [scrollPosition]);

  // END Button Style

  return (

    <div className="z-[10] max-w-[560px]">
      <section className="flex !justify-between items-center !gap-[10px] fixed bottom-0 w-full pb-[18px] max-w-[560px] z-[10]">
        {collaborator.form ? (
          <>
            <button
              className="h-[50px] !w-[12rem] text-white bg-black rounded-md !text-[13px] flex items-center justify-center gap-3"
              // style={collaborator?.branding?.color ? {...button, background: `${collaborator.branding.color}`} : button}
              style={button}
              id="btn-get-contact-2"
              // onClick={handlePopupOpen}
              onClick={handleDownload}
            >
              <span className='inline-block max-w-[100px]'>
                {
                  !fetchGetVcard ?
                    (translation?.mainButton?.addContact?.[lang])
                    : <img src="/assets/img/loaders/loading.gif" alt='loading Vcard' className='w-[23px] h-[23px]' />
                }
              </span>
            </button>
            <button
              className='h-[50px] !w-[12rem] text-white bg-black rounded-md !text-[13px]  !mr-[40px] ss:!mr-[20px]'
              style={button}
              onClick={handleIdentify}
            >
              <span className='inline-block max-w-[100px]'>{translation?.mainButton?.identifyContact?.[lang]}</span>
            </button>
          </>
        ) : (
          <button
            className="h-[50px] w-full rounded-md text-base flex items-center justify-center gap-3 mx-auto !mr-[40px] ss:!mr-[20px]"
            // style={collaborator?.branding?.color ? {...button, background: `${collaborator.branding.color}`} : button}
            style={button}
            id="btn-get-contact-2"
            // onClick={handlePopupOpen}
            onClick={handleDownload}
          >
            <span className='inline-block w-full'>
              {
                !fetchGetVcard ?
                  (translation?.mainButton?.addContact?.[lang])
                  : <img src="/assets/img/loaders/loading.gif" alt='loading Vcard' className='w-[23px] h-[23px]' />
              }
            </span>
          </button>
        )}
      </section>


      {openIdentification && collaborator.form && (
        <IdentificationForm close={setOpenIdentification} dataRes={dataRes} />
      )}

      {openFormContactLock && (
        <ContactLock
          id={collaborator._id}
          setOpenFormContactLock={setOpenFormContactLock}
          contactAccess={contactAccess}
          setContactAccess={setContactAccess}
          handleDownload={handleDownload}
        />
      )}

    </div>
  );
};

export default AddToContactButtonFloat;
