import React, { useEffect, useState } from "react";
// import { useGlobalState } from "..";
import { translation } from "../../../translation";
// import AppServices from "../services/AppServices";
import { useSelector } from "react-redux";
import { useCheckSlugMutation, usePatchCollaboratorMutation } from "../../../redux/appSlices/collaborator/collaboratorApiSlice";

const SettingSlug = ({ setStep2, setStep1, setSlug, slug }) => {

  const collaborator = useSelector((state) => state.collaborator.collaboratorInfo);
  const lang = useSelector((state) => state.collaborator.collaboratorInfo.language);

  const [customLink, setCustomLink] = useState("");
  const [slugError, setSlugError] = useState(false);
  const [safe, setSafe] = useState(false);
  const [safeR, setSafeR] = useState(false);
  // const [branding] = useGlobalState("branding");
  const branding = null

  const collaborator_id = collaborator.collaborator_id
  const [checkSlug] = useCheckSlugMutation()
  const [patchCollaborator] = usePatchCollaboratorMutation()

  useEffect(() => {
    if (customLink.length > 2) {
      checkSlug({
        slug: customLink,
      }).unwrap().then((res) => {
        if (res.status === "success") {
          setSafe(true);
          setSlugError(false);
        } else {
          setSafe(false);
          setSlugError(true);
        }
      }).catch((error) => {
        setSafe(false);
        setSlugError(true);
      });
    } else {
      setSafe(false);
    }
  }, [customLink]);

  useEffect(() => {
    if (slug !== collaborator_id && slug) {
      setSafeR(true);
    } else {
      setSafeR(false);
    }
  }, [slug]);

  // Event handlers
  const handleKeyDown = (event) => {
    if (event.keyCode === 32) {
      event.preventDefault();
    }
  };

  const handleInputChange = (e) => {
    const input = e.target.value;
    const regex = /^[a-zA-Z0-9-_]*$/; // Allow only alphanumeric characters, hyphens, and underscores

    if (regex.test(input)) {
      setCustomLink(input);
    } else {
      const sanitizedInput = input.slice(0, -1); // Remove the last character
      if (regex.test(sanitizedInput)) {
        setCustomLink(sanitizedInput);
      }
    }
  };

  const handleCheckSubmit = () => {
    if (safe && !slugError) {
      patchCollaborator({
        id:collaborator._id,
        content : {
          slug: customLink
        }
      }).unwrap().then((res) => {
        setStep1(false);
        setStep2(false);
        // clear
        setCustomLink("");
      })
    }
  };

  const handleRetablirSubmit = () => {
    // AppServices.post(
    //   {
    //     action: 5,
    //     data: {
    //       slug: "",
    //     },
    //   },
    //   "/app"
    // ).then((res) => {
    //   setSlug(collaborator.collaborator_id);

    //   // seteditComponantId(-1);

    //   // set steps false
    //   setStep1(false);
    //   setStep2(false);
    //   // clear
    // });
    patchCollaborator({
      id:collaborator._id,
      content : {
        slug: ''
      }
    }).unwrap().then((res) => {
      setStep1(false);
      setStep2(false);
    })
  };

  return (
    <section className="fields-content overflow-hidden pt-[7vh] mx-5">
      <img
        src="../../assets/img/icons/arrow-back.svg"
        alt=""
        srcSet=""
        onClick={() => {
          setStep1(false);
        }}
        className="cursor-pointer absolute top-0 mt-[40px] ml-[-10px]"
      />

      <div className="text-2xl font-SteradianMedium">
        {translation.settings["custom-link-section"].title[lang]}{" "}
      </div>
      <div className="mt-[1rem] text-[17px] font-[500]">
        {translation.settings["custom-link-section"].subtitle[lang]}
      </div>
      <div className="text-[18px] text-[#4E3131] mt-[1rem]">
        {translation.settings["custom-link-section"].lienactuel[lang]}
      </div>
      <div className="text-[16px] text-[#795656]">
        {branding?.vcard_url ? branding?.vcard_url + "/" : "app.getpopcard.com/"}

        <span className="text-[#795656]">{slug}</span>
      </div>

      <div className="text-[18px] text-[#4E3131] mt-[1rem]">
        {translation.settings["custom-link-section"].lien[lang]}
      </div>
      <div className="text-[16px] text-black flex">
        {branding?.vcard_url ? branding?.vcard_url + "/" : "app.getpopcard.com/"}
        <div className="relative">
          <input
            type="text"
            className={`outline-none w-full
                    ${slugError ? "errorInputText" : "input-custom-link bg-transparent"}
                    ${safe ? "SucsessInputText" : "input-custom-link bg-transparent"}
                  `}
            value={customLink}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
          {safe && (
            <>
              <hr className="bg-[#42A84A] h-[0.5px] w-full border-none" />
              <img
                src="/assets/img/icons/rightcheck.svg"
                alt=""
                className="w-[15px] h-[15px] object-contain absolute right-0 top-1"
              />
            </>
          )}
          {slugError && (
            <>
              <hr className="bg-[#C00808] h-[0.5px] w-full border-none" />
              <img
                src="/assets/img/icons/wrong.svg"
                alt=""
                className="w-[15px] h-[15px] object-contain absolute right-0 top-1"
              />
            </>
          )}
          {!safe && !slugError && (
            <hr className="bg-[#795656] h-[0.5px] w-full border-none" />
          )}
        </div>
      </div>
      {safe && (
        // sucuss text
        <div className="text-[14px] text-[#42A84A] mt-[0.5rem]">
          {translation.settings["custom-link-section"].success[lang]}
        </div>
      )}
      {slugError && (
        // wrong text
        <div className="text-[14px] text-[#C00808] mt-[0.5rem]">
          {translation.settings["custom-link-section"].error[lang]}
        </div>
      )}

      <div className="button-container flex flex-col justify-center gap-[5px]">
        <div className="button-container-setting">
          <button
            className={`!relative ${safe ? "btn-fields btn-active" : "btn-fields"}`}
            id="btn-next"
            onClick={handleCheckSubmit}
            disabled={!safe}
          >
            {translation?.loginPage?.buttonSave?.[lang]}
          </button>
        </div>
        <div className="button-container-rest">
          <button
            className={`!relative ${safeR ? "btn-fields btn-active" : "btn-fields"}`}
            id="btn-next"
            onClick={handleRetablirSubmit}
            disabled={!safeR}
          >
            {translation?.settings?.["custom-link-section"]?.button?.[lang]}
          </button>
        </div>

      </div>
    </section>
  );
};

export default SettingSlug;
