import React, { useState } from 'react'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { RiUploadCloud2Fill } from "react-icons/ri";
import { MdCancel } from "react-icons/md";
import { useDeleteCollaboratorpdfMutation, useUploadPdfMutation } from '../../redux/appSlices/collaborator/collaboratorApiSlice';
import { translation } from '../../translation';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function SingleLink({ data, lang, index, handleChange, handleDelete, handleImageUpload, handleTypeChange, handleUpdatelink }) {

  const [uploadPdf, { isLoading }] = useUploadPdfMutation()
  const [deleteCollaboratorpdf] = useDeleteCollaboratorpdfMutation()
  const [openList, setOpenList] = useState(false)

  const handleUploadPdf = (e, id) => {
    const pdfFile = e.target.files[0];
    if (pdfFile) {
      uploadPdf(pdfFile)
        .unwrap()
        .then((res) => {
          handleUpdatelink(id, res.data.pdf)
        })
        .catch((error) => {
          toast.error(error?.data?.message?.[lang]);
        });
    }
  };

  const handleDeletePdf = (e, id, fileName) => {
    deleteCollaboratorpdf({ pdf: fileName })
    .unwrap()
    .then((res) => {console.log(fileName); handleUpdatelink(id, '')})
    .catch((err) => { console.log(err) })
  }

  return (
    <div className='flex gap-[14px] mt-20'>
      <div className='h-[151px] w-[106px]'>
        {data.thumb ?
          (<img className='rounded-lg h-full w-[106px] object-cover' src={`${process.env.REACT_APP_IMAGE_PATH}${data.thumb}`} alt={data.thumb?.[lang]} loading='lazy' />
          ) : (
            <>
              <label htmlFor={`addImageLink_${data._id}`} className='cursor-pointer text-labels border border-dashed border-[#ccb7b7] rounded-[9px] flex flex-col items-center justify-center h-[151px] w-[106px]'>
                <span className='text-center'>Upload a preview </span>
                <img src="../../assets/img/icons/plus-circle.svg" alt="Add link image" />
              </label>
              <input id={`addImageLink_${data._id}`} className='hidden' name="thumb" type="file" onChange={(e) => handleImageUpload(e, index)} />
            </>
          )}
      </div>
      <div className='flex flex-col justify-between'>
        <div
          className='relative flex items-center justify-between w-[122px] h-[44px] bg-labelsBg border border-labels rounded-lg py-2 px-5 cursor-pointer'
          onClick={() => setOpenList(!openList)}
        >
          {data.type ? data.type : 'url'}
          <img class={`w-[11px] h-[11px] ${openList ? 'rotate-180' : ''}`} src="../../assets/img/icons/arrow-down.svg" alt="show option" />
          {openList && (
            <div className='absolute top-[54px] left-0 flex flex-col items-stretch w-[180px] h-[80px] bg-[#fff3e7] border border-[#ccb7b7] rounded-[4px] z-20'>
              <div className='p-[8px] hover:bg-[#ccb7b7]' onClick={() => handleTypeChange(index, 'pdf')}>PDF</div>
              <div className='p-[8px] hover:bg-[#ccb7b7]' onClick={() => handleTypeChange(index, 'url')}>LINK</div>

            </div>
          )}
        </div>

        {
          data.type !== 'pdf' ? (
            <input
              className='block bg-transparent w-full text-lg border-b border-inputBottom focus:outline-none'
              name="url"
              onChange={(e) => handleChange(e, index)}
              placeholder='URL(*)'
              value={data?.url}
            />
          ) : (
            <>
              <label
                htmlFor={`uploadPdf_${data._id}`}
                className='flex items-center justify-between bg-labelsBg border border-labels border-dashed rounded-lg py-[4px] px-[8px] mt-2 cursor-pointer'
              >

                {
                  isLoading ?
                    (<>Loading... < MdCancel /></>)
                    : (data.url
                      ? (<div className='flex items-center justify-between w-full' onClick={(e) => {console.log('data._id', data._id);handleDeletePdf(e, data._id, data.url)}}>
                          {/* {data.url.slice(0,20)}  */}
                          {data.url.split('.')[0].slice(0,10)+'...pdf'}
                          < MdCancel />
                        </div>)
                      : <>{translation.linkAndPdfs.uploadFile?.[lang]} < RiUploadCloud2Fill /> </>)
                }

              </label>


              {!data.url && <input id={`uploadPdf_${data._id}`} type='file' className='hidden' accept="application/pdf" onChange={(e) => handleUploadPdf(e, data._id)} />}
            </>
          )
        }

        <input
          className='block bg-transparent w-full text-lg border-b border-inputBottom focus:outline-none'
          name="title"
          onChange={(e) => handleChange(e, index)}
          placeholder='Title(*)'
          value={data?.title?.[lang]}
        />
        <input
          className='block bg-transparent w-full text-lg border-b border-inputBottom focus:outline-none'
          name="description"
          onChange={(e) => handleChange(e, index)}
          placeholder='Description'
          value={data?.description?.[lang]}
        />
      </div>
      <div className='cursor-pointer ml-auto' onClick={() => handleDelete(data._id)}>
        <img src="../../assets/img/icons/delete.svg" alt="delete link" />
      </div>
    </div>
  )
}

export default SingleLink